import { Fragment, useContext, useState } from "react";
import { Dialog, Disclosure, Popover, Transition } from "@headlessui/react";
import { FaChevronDown, FaBars, FaXmark } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import logoImage from "../../../assets/layout/nav-bar/logo.svg";
import logoMateImage from "../../../assets/layout/nav-bar/logo-mate.svg";
import routePaths from "../../constants/route-paths";

const moreItems = [
  { name: "Projekt-információk", href: routePaths.HOME_PROJECTINFO },
  { name: "Rólunk", href: routePaths.HOME_ABOUTUS },
  { name: "Energetikai alapfogalmak", href: routePaths.HOME_ENERGETICDEFS },
  { name: "Felhasználási feltételek", href: routePaths.HOME_TERMSOFUSE },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

type Props = {
  title?: string;
  path?: string;
};

const Header = ({ title, path }: Props) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  //   const { offerDataState, dispatch } = useContext(OfferDataContext);

  const withTextClass = (classNames: string, isBold?: boolean): string => {
    let result = "text-xl";

    if (isBold === true) {
      result += " font-semibold";
    }

    result += " " + classNames;

    return result;
  };

  const desktopMenuLogo = () => {
    return (
      <div className="flex w-[260px]">
        <div className="cursor-pointer">
          <div onClick={() => navigate(routePaths.HOME)}>
            <img className="h-[48px]" src={logoImage} title="Kezdő képernyő" />
          </div>
        </div>
        <div className="mx-4">
          <div>
            <img className="h-[48px]" src={logoMateImage} title="" />
          </div>
        </div>
      </div>
    );
  };

  const desktopMenuItem = (
    title: string,
    href: string,
    resetOffer: boolean = false
  ) => {
    return (
      <div
        className={withTextClass(
          "leading-6 text-gray-900 p-2 cursor-pointer",
          true
        )}
        onClick={() =>
          resetOffer
            ? navigate(href, {
                state: { resetOffer: true },
              })
            : navigate(href)
        }
      >
        {title}
      </div>
    );
  };

  const desktopMoreMenuItem = () => {
    return (
      <Popover.Button
        className={withTextClass(
          "flex items-center gap-x-2 leading-6 text-gray-900 p-2",
          true
        )}
      >
        TOVÁBBIAK
        <FaChevronDown
          className="h-5 w-5 flex-none text-gray-400"
          aria-hidden="true"
        />
      </Popover.Button>
    );
  };

  const desktopMoreMenu = () => {
    return (
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute -left-28 top-full z-30 mt-3 max-w-md overflow-hidden whitespace-nowrap rounded bg-white shadow-lg ring-1 ring-gray-900/5">
          {({ close }) => (
            <div className="p-4">
              {moreItems.map((moreItem) => (
                <div
                  key={moreItem.name}
                  className={withTextClass(
                    "group relative flex items-center gap-x-6 rounded p-3 leading-6 hover:bg-gray-50"
                  )}
                >
                  <div className="flex-auto">
                    <div
                      onClick={() => {
                        navigate(moreItem.href);
                        close();
                      }}
                      className="block font-semibold text-gray-900 cursor-pointer"
                    >
                      {moreItem.name}
                      <span className="absolute inset-0" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </Popover.Panel>
      </Transition>
    );
  };

  const openHamburgerMenuButton = () => {
    return (
      <button
        type="button"
        className="-m-2.5 inline-flex items-center justify-center rounded p-2.5 text-gray-700"
        onClick={() => setMobileMenuOpen(true)}
      >
        <span className="sr-only">Menüt megnyit</span>
        <FaBars className="h-6 w-6" aria-hidden="true" />
      </button>
    );
  };

  const mobileMenuItem = (
    title: string,
    href: string,
    resetOffer: boolean = false
  ) => {
    return (
      <div
        className={withTextClass(
          "-mx-3 block rounded px-3 py-2 leading-7 text-gray-900 hover:bg-gray-50 cursor-pointer",
          true
        )}
        onClick={() => {
          setMobileMenuOpen(false);
          resetOffer
            ? navigate(href, {
                state: { resetOffer: true },
              })
            : navigate(href);
        }}
      >
        {title}
      </div>
    );
  };

  const closeHamburgerMenuButton = () => {
    return (
      <button
        type="button"
        className="-m-2.5 rounded p-2.5 text-gray-700"
        onClick={() => setMobileMenuOpen(false)}
      >
        <span className="sr-only">Menüt bezár</span>
        <FaXmark className="h-6 w-6" aria-hidden="true" />
      </button>
    );
  };

  const mobileMoreMenuItem = (open: boolean) => {
    return (
      <Disclosure.Button
        className={withTextClass(
          "flex w-full items-center justify-between rounded py-2 pl-3 pr-3.5 leading-7 text-gray-900 hover:bg-gray-50",
          true
        )}
      >
        TOVÁBBIAK
        <FaChevronDown
          className={classNames(open ? "rotate-180" : "", "h-5 w-5 flex-none")}
          aria-hidden="true"
        />
      </Disclosure.Button>
    );
  };

  const mobileMoreMenu = () => {
    return (
      <Disclosure.Panel className="mt-2 space-y-2 z-30">
        {() => (
          <>
            {moreItems.map((moreItem) => (
              <button
                key={moreItem.name}
                className={withTextClass(
                  "block rounded w-full text-left py-2 pl-6 pr-3 leading-7 text-gray-900 hover:bg-gray-50 cursor-pointer",
                  true
                )}
                onClick={() => {
                  setMobileMenuOpen(false);
                  navigate(moreItem.href);
                }}
              >
                {moreItem.name}
              </button>
            ))}
          </>
        )}
      </Disclosure.Panel>
    );
  };

  return (
    <header className="flex items-center bg-white sticky top-0 z-20 h-[100px] shadow-lg">
      <nav
        className="mx-auto flex w-full items-center px-5"
        aria-label="Global"
      >
        <div className="flex flex-none">{desktopMenuLogo()}</div>
        <div className="flex grow justify-end">
          <div className="flex md:hidden">{openHamburgerMenuButton()}</div>
          <Popover.Group className="hidden md:flex md:gap-x-4">
            {desktopMenuItem("AJÁNLÁS", routePaths.OFFER, true)}
            {desktopMenuItem("MEGOLDÁSOK", routePaths.HOME_SOLUTIONS)}
            {desktopMenuItem("KIVITELEZŐK", routePaths.CONTRACTOR)}

            <Popover className="relative">
              {desktopMoreMenuItem()}
              {desktopMoreMenu()}
            </Popover>
          </Popover.Group>
        </div>
      </nav>
      <Dialog
        as="div"
        className="md:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-30 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex justify-end">{closeHamburgerMenuButton()}</div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {mobileMenuItem("AJÁNLÁS", routePaths.OFFER, true)}
                {mobileMenuItem("MEGOLDÁSOK", routePaths.HOME_SOLUTIONS)}
                {mobileMenuItem("KIVITELEZŐK", routePaths.CONTRACTOR)}

                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      {mobileMoreMenuItem(open)}
                      {mobileMoreMenu()}
                    </>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
};

export default Header;
