import { useState } from "react";
import PageSection from "../../../common/components/page-section";
import PageSectionMargin from "../../../common/components/page-section/page-section-margin";
import PageSectionContent from "../../../common/components/page-section/page-section-content";
import backgroundImage from "../../../assets/home/energetic-defs/bg.jpg";
import EnergeticDefinitionItem from "./energetic-definition-item";
import Modal, { ModalState } from "../../../common/components/modal";
import { functionalBasicsContent } from "./details/functional-basics-content";
import { systemPartsContent } from "./details/system-parts-content";
import { solutionsOfPowerContent } from "./details/solutions-of-power-content";
import { solutionsOfThermalOrFuelContent } from "./details/solutions-of-thermal-or-fuel-content";
import { possibleEnergySourcesContent } from "./details/possible-energy-sources-content";
import { energyAndSourcesContent } from "./details/energy-and-sources-content";

const EnergeticDefinitions = () =>
{
    const [modalState, setModalState] = useState<ModalState>({ isOpen: false });

    return (
        <>
            <Modal state={modalState} setState={setModalState}></Modal>
            <PageSection
                id="energetic-defs"
                additionalClassName="bg-clip-border bg-origin-border bg-center bg-no-repeat bg-cover"
                style={{ backgroundImage: "url(" + backgroundImage + ")" }}
            >
                <PageSectionMargin additionalClassName="">
                </PageSectionMargin>
                <PageSectionContent>
                    <div className="w-full text-5xl text-black font-bold text-center">
                        Energetikai alapfogalmak
                    </div >
                    <div className="w-full mt-12 text-3xl border-2 border-black/25 rounded bg-white/20 backdrop-blur-sm divide-y-2 divide-black/20 shadow-xl">
                        <EnergeticDefinitionItem
                            title={energyAndSourcesContent.title}
                            onClick={() => { setModalState({ isOpen: true, title: energyAndSourcesContent.title, content: energyAndSourcesContent.body }); }}>
                        </EnergeticDefinitionItem>
                        <EnergeticDefinitionItem
                            title={possibleEnergySourcesContent.title}
                            onClick={() => { setModalState({ isOpen: true, title: possibleEnergySourcesContent.title, content: possibleEnergySourcesContent.body }); }}>
                        </EnergeticDefinitionItem>
                        <EnergeticDefinitionItem
                            title={solutionsOfThermalOrFuelContent.title}
                            onClick={() => { setModalState({ isOpen: true, title: solutionsOfThermalOrFuelContent.title, content: solutionsOfThermalOrFuelContent.body }); }}>
                        </EnergeticDefinitionItem>
                        <EnergeticDefinitionItem
                            title={solutionsOfPowerContent.title}
                            onClick={() => { setModalState({ isOpen: true, title: solutionsOfPowerContent.title, content: solutionsOfPowerContent.body }); }}>
                        </EnergeticDefinitionItem>
                        <EnergeticDefinitionItem
                            title={systemPartsContent.title}
                            onClick={() => { setModalState({ isOpen: true, title: systemPartsContent.title, content: systemPartsContent.body }); }}>
                        </EnergeticDefinitionItem>
                        <EnergeticDefinitionItem
                            title={functionalBasicsContent.title}
                            onClick={() => { setModalState({ isOpen: true, title: functionalBasicsContent.title, content: functionalBasicsContent.body }); }}>
                        </EnergeticDefinitionItem>
                    </div>
                </PageSectionContent>
                <PageSectionMargin additionalClassName="">
                </PageSectionMargin>
            </PageSection >
        </>
    );
};

export default EnergeticDefinitions;
