import PageSection from "../../../common/components/page-section";
import PageSectionMargin from "../../../common/components/page-section/page-section-margin";
import PageSectionContent from "../../../common/components/page-section/page-section-content";
import greenPlanetImage from "../../../assets/home/about-us/green-planet.jpg";

const AboutUs = () =>
{
    return (
        <PageSection id="about-us">
            <PageSectionMargin additionalClassName="bg-black">
            </PageSectionMargin>
            <PageSectionContent additionalClassName="bg-black">
                <div className="flex grid grid-cols-1 gap-8 lg:grid-cols-2 text-white">
                    <div>
                        <div className="w-full text-5xl font-bold text-center">
                            Rólunk
                        </div>
                        <p className="text-xl text-justify mt-12">
                            A MATE Körforgásos Gazdaság Elemző Központja egy olyan szervezet, amely:
                        </p>
                        <ul className="list-disc text-xl text-justify mt-4 ml-4">
                            <li>
                                önálló projekteket koordinál,
                            </li>
                            <li>
                                elemzési, kutatási szolgáltatásokat végez,
                            </li>
                            <li>
                                nyilvántartja az Egyetemen folyó, a körforgásos gazdasághoz kapcsolódó kutatási és oktatási
                                tevékenységeket és azok eredményeit, a közösségi média felületein keresztül biztosítja a
                                legújabb ismeretek naprakész megosztását.
                            </li>
                        </ul>
                        <p className="text-xl text-justify mt-4">
                            Központvezető: Prof. Dr. Boros Anita egyetemi tanár.
                        </p>
                        <p className="text-xl text-justify mt-4">
                            Weboldal: <a className="text-blue-400" target="_blank" href="http://korforgas.uni-mate.hu">korforgas.uni-mate.hu</a>
                        </p>
                    </div>
                    <img className="h-[400px] w-full object-cover lg:w-auto lg:object-none" src={greenPlanetImage} />
                </div>
            </PageSectionContent>
            <PageSectionMargin additionalClassName="bg-black">
            </PageSectionMargin>
        </PageSection>
    );
};

export default AboutUs;
