import { detailStyles } from "./detail-styles";

export const energyAndSourcesContent = {
    title: "Energia - Energiaforrások",
    body: (
        <>
            <p className={detailStyles.text}>
                Energia (fizikai értelemben) az anyag alapvető tulajdonsága a munkavégző képességre.
            </p>

            <p className={detailStyles.text}>
                Megkülönböztetnek gravitációs/tömegvonzási, mechanikai (az anyag /test helyzeti (vagy potenciális) és mozgási/deformációs) energiájának az összegét,
                a villamos, hő, vegyi/kémiai, sugárzási, nukleáris, stb. energiát.
            </p>

            <p className={detailStyles.text}>
                Energiaforrásnak nevezik az olyan anyagot, melyben kötött felhasználható energiák vannak, illetve melyből, megfelelő berendezések alkalmazásával az
                energia kitermelhető és felhasználható. Pl. a kazánokban az anyag elégetésével hőenergia termelhető, vagy a szél generátort forgatva áramot termel.
            </p>

            <p className={detailStyles.text}>
                Az energiát energetikai rendszerekben állítják elő, tárolják, továbbítják, használják fel. Ilyen ismert rendszer a villamosáram hálózat.
            </p>

            <p className={detailStyles.text}>
                A lakossági energia felhasználást egy házra, családra leszűkítve mikro energetikai rendszernek nevezik. A rendszerben egyszerre több energiaforrás,
                energiatermelő berendezés és számos energiafelhasználó is lehet.
            </p>
        </>
    )
};
