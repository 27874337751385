import { ContractorSearchResultModel } from "./models";
import openIcon from "../../assets/home/energetic-defs/open-icon.svg";
import { useNavigate } from "react-router-dom";

type Props = { model: ContractorSearchResultModel };

const ContractorCard = ({ model }: Props) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="text-base">{model.name}</div>
      {model.hits.map((item) => (
        <div className="flex">
          <div className="w-[100%] h-auto mt-2 p-2">
            <div className="text-lg font-bold">{item.name}</div>
            <div className="text-lg">{item.address}</div>
            <div className="text-lg">{item.distanceInKms} km távolságban</div>
          </div>
          <div
            className="flex text-lg text-smart-darkgray ml-auto items-center"
            onClick={() => {
              window.open(item.web, "_blank");
            }}
          >
            <img className="w-6 h-6 mr-2" src={openIcon} title="" />
          </div>
        </div>
      ))}
    </>
  );
};

export default ContractorCard;
