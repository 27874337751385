import { ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";

type Props = {
    children: ReactNode;
};

const ScrollHandler = ({ children }: Props) =>
{
    const { pathname, hash } = useLocation();

    const handleScroll = () =>
    {
        const element = document.getElementById(hash.replace("#", ""));

        setTimeout(() =>
        {
            window.scrollTo({
                behavior: element ? "smooth" : "auto",
                top: element ? element.offsetTop - 100 : 0
            });
        }, 10);
    };

    useEffect(() => { handleScroll(); }, [pathname, hash]);

    return (
        <>
            {children}
        </>
    );
};

export default ScrollHandler;