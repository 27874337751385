import { FieldErrorsImpl, FieldValues } from "react-hook-form";

type Props = {
    errors: Partial<FieldErrorsImpl<FieldValues>>;
    errorKey: string;
};

export default function FormError({ errors, errorKey }: Props)
{
    return errors && Object.keys(errors).includes(errorKey) ? (
        <div className="absolute bottom-0 animate-fadeIn font-medium text-smart-red">
            {errors[errorKey]?.message as string}
        </div>
    ) : null;
}
