import { createContext, Dispatch, Reducer, useEffect, useReducer } from "react";
import { post } from "../api/api.service";
import { LookupList } from "../types/lookupList";
import { Libraries, useLoadScript } from "@react-google-maps/api";

export interface GlobalState {
  items: LookupList;
  mapIsLoaded: boolean;
}

type Prop = {
  children: JSX.Element;
};

const initialState: GlobalState = {
  mapIsLoaded: false,
  items: {
    lookups: {
      buildingCharacteristics: [],
      epcs: [],
      cellars: [],
      doors: [],
      energySources: [],
      heatExchangers: [],
      roofs: [],
      shadings: [],
      walls: [],
      windows: [],
    },
  },
};

export enum GlobalActionType {
  getLookups,
  setMapLoaded,
}

export interface GlobalAction {
  type: GlobalActionType;
  params?: unknown;
}

const globalReducer: Reducer<GlobalState, GlobalAction> = (
  prevState,
  action
) => {
  switch (action.type) {
    case GlobalActionType.getLookups: {
      const items = action.params as LookupList;
      return { ...prevState, items };
    }
    case GlobalActionType.setMapLoaded: {
      const item = action.params as boolean;
      return { ...prevState, mapIsLoaded: item };
    }
    default:
      return prevState;
  }
};

export const GlobalContext = createContext<{
  state: GlobalState;
  dispatch: Dispatch<GlobalAction>;
}>({
  state: initialState,
  dispatch: (_value) => {
    return;
  },
});

async function onInit(dispatch: Dispatch<GlobalAction>) {
  const response = await post<LookupList>(
    "lookups",
    {},
    {
      headers: {
        accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "post",
      },
    }
  );

  dispatch({
    type: GlobalActionType.getLookups,
    params: response,
  });
}

const libraries = ["places"] as Libraries;

export const GlobalProvider = ({ children }: Prop) => {
  const [state, dispatch] = useReducer(globalReducer, initialState);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDMCFzmXLHAiPNredtCznFUgl20IQBc1Lo",
    libraries,
  });

  useEffect(() => void onInit(dispatch), []);

  useEffect(() => {
    if (loadError) {
      console.log("Error: ", loadError);
    } else {
      dispatch({ type: GlobalActionType.setMapLoaded, params: isLoaded });
    }
  }, [isLoaded, loadError]);

  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalContext.Provider>
  );
};
