import { ReactNode } from "react";
import Header from "../header";
import Footer from "../footer";
import emptyImage from "../../../assets/layout/empty-state/empty-state.svg";
import { isMobile } from "react-device-detect";
import ScrollHandler from "../scroll-handler";


type Props = {
    children: ReactNode;
    hideHeader?: boolean;
    hideFooter?: boolean;
};

const Layout = ({ hideHeader, hideFooter, children }: Props) =>
{
    return (
        <>
            <div className="flex sm:hidden min-h-screen items-center justify-center">
                <div className="w-[300px] grid items-center justify-center space-y-10 text-center">
                    <div className="text-5xl text-[#91A500] animate-pulse">
                        Figyelem!
                    </div>
                    <div className="flex items-center justify-center">
                        <img className="w-[220px]" src={emptyImage} />
                    </div>
                    <div className="text-3xl text-[#91A500]">
                        {isMobile ? "Forgasd el a készüléket a jobb felhasználói élményhez!" : "Állítsd az ablakot szélesebbre a jobb felhasználói élményhez!"}

                    </div>
                </div>
            </div>
            <ScrollHandler>
                <div className="hidden sm:flex sm:flex-col sm:min-h-screen relative">
                    {!hideHeader ? (<Header />) : (<></>)}
                    {children}
                    {!hideFooter ? (<Footer />) : (<></>)}
                </div>
            </ScrollHandler>
        </>
    );
};

export default Layout;
