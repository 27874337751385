import { detailStyles } from "./detail-styles";

export const solutionsOfPowerContent = {
    title: "Energiatermelési megoldások - áram",
    body: (
        <>
            <p>
                <span className={detailStyles.highlightStrong}>Hálózat</span> energia, energiaforrás felhasználókhoz továbbítására szolgáló
                (villamosenergia, vagy gázszállító csőhálózat) rendszer, mely tartalmazza az energiatermelő, tároló és a rendszer működtetéséhez
                szükséges felügyeleti és menedzsment eszközöket is.
            </p>

            <p>
                <span className={detailStyles.highlightStrong}>Hálózatba kapcsolt mikro energetikai rendszer</span> a lakossági/háztartási mikro
                energetikai rendszerek jellemzően egy-három megújuló, vagy alternatív energiaforrást tartalmaznak (pl. napelem, hőszivattyú).
            </p>

            <p>
                <span className={detailStyles.highlightStrong}>Napelem</span> a napenergiát villamosenergiává alakító eszköz. Lakossági mikro
                energetikai rendszerekben legtöbbet használt (hazai viszonylatban a viszonylag sok és eléggé intenzív napsugárzás miatt hatékonyan
                alkalmazható) napelemek fejlesztése, gyártása ma is gyors.
            </p>

            <p>
                <span className={detailStyles.highlightStrong}>Szélturbina</span> szélenergiát alakít át villamosenergiává speciális elemeken
                (lapátok) keletkező légerők által forgatott tengelyre kötött villamos generátor alkalmazásával. Létezik vízszintes és függőleges
                tengelyű szélturbina, sőt hagyományos turbinalapátok nélküli energiatermelő megoldás is. Fontos: a háztartási energiairendszerekhez
                fejlesztett függőleges szélturbinák zaja ma már kisebb, mint a széles körben használt háztartási gépeké.
            </p>

            <p>
                <span className={detailStyles.highlightStrong}>Vízturbina</span> forgó erőgép, a mozgó víz energiáját alakítja villamosenergiává.
                létezik kisméretű és óriási változata is.
            </p>

            <p>
                <span className={detailStyles.highlightStrong}>Kapcsolt áramtermelés</span> azonos energiatermelő egység (belsőégésű motor,
                gázturbina) alkalmazása fűtésre és villamosáramtermelésre (co-generation), és fűtésre, hűtésre, villamos áram termelésre
                (trigeneration).
            </p>

            <p>
                <span className={detailStyles.highlightStrong}>Hidrogén</span> fajlagosan a dízel üzemanyaghoz képest 2,64-szer több energiát tartalmazó, ma már
                bizonyítottan biztonságosan is alkalmazható energiaforrás. A hidrogén elő lehet állítani a víz bontásával, a gázból szénből, biomasszából, stb.
                hőkezeléssel, vegyi / elektromos bontással, vagy akár plazma alkalmazásával. Fontos: egy sor problémát kell még megoldani, kezdve a társadalmi
                elfogadástól, el kell érni a jelenlegi hidrogén előállítási költségek jelentős 60-80%-os csökkentését, széles körben ki kell alakítani a hidrogén
                biztonságos tárolását, szállítását. További érdekes, nem közismert tény, a hidrogén elégetésekor a melegház hatást kifejtő nitrogénoxidok
                keletkeznek, jelentős mértékben.
            </p>

            <p>
                <span className={detailStyles.highlightStrong}>Üzemanyag/hidrogén cella</span> viszonylag még ma is új technológiának tekinthető
                olyan berendezés, mely az üzemanyagokat, vagy éppen a hidrogént felhasználva közvetlen elektromos energiát termel (hagyományos
                tüzelés nélkül).
            </p>
        </>
    )
};