import { CarouselSlidesData } from "./carousel-slides-data";
import image1 from "../../../assets/home/carousel/image1.jpeg";
import image2 from "../../../assets/home/carousel/image2.jpeg";
import image3 from "../../../assets/home/carousel/image3.jpeg";
import image4 from "../../../assets/home/carousel/image4.jpeg";

export const carouselData: CarouselSlidesData = {
    slides: [
        {
            image: image1,
            title: "Üdvözöljük az EnergoSmart oldalon!",
            message: ["Kíváncsi, hogy az Ön lakóhelyén mi lenne az energia-előállító technológiák optimális mixe?"]
        },
        {
            image: image2,
            title: "Kalkuláljon velünk!",
            message: ["Használja egyszerű kalkulátorunkat a gyors tájékozódáshoz vagy bővített kalkulátorunkat a pontosabb eredmény eléréséhez."]
        },
        {
            image: image3,
            title: "Helyi sajátosságok szerinti eredmények!",
            message: ["Algoritmusunk tudományos számításokkal, geológiai adatbázisokra támaszkodva, a mesterséges intelligencia támogatásával adja meg az eredményeket."]
        },
        {
            image: image4,
            title: "A legelterjedtebb technológiákat ajánljuk!",
            message: ["Az eredményben számba vesszük a napelem, napkollektor, szélturbina, hőszivattyú, geotermikus energia, gáz és hálózati energia felhasználásának lehetőségét."]
        }
    ]
};