import SVG from "react-inlinesvg";
import backIcon from "../../../assets/solutions/back-icon-dark.svg";
import img from "../../../assets/solutions/geothermic.jpeg";
import { useNavigate } from "react-router-dom";
import routePaths from "../../../common/constants/route-paths";
import { useContext } from "react";
import { HeaderPathContext } from "../../../common/providers/header-path-context";
import { FaArrowLeft } from "react-icons/fa6";

const Windturbine = () => {
  const navigate = useNavigate();
  const { headerPathState, dispatch: headerPathDispatch } =
    useContext(HeaderPathContext);

  return (
    <div className="flex justify-center">
      <div className="w-full h-[560px] absolute">
        <img className="w-full min-h-[380px] max-h-[600px]" src={img} />
      </div>
      <div className="flex relative">
        <div className="flex w-full">
          <div className="grow w-0"></div>
          <div className="shrink w-[1024px] min-w-[640px] z-10 pt-[160px] pb-[80px]">
            <div className="flex border-[1px] border-smart-lightgray bg-smart-gray-white rounded-[4px] justify-center items-center pb-[80px]">
              <div className="w-[480px]">
                <div className="mt-[30px]">
                  <div className="flex items-center">
                    <div
                      className="inline-flex items-center cursor-pointer"
                      onClick={() => {
                        navigate(
                          headerPathState.path ?? routePaths.HOME_SOLUTIONS
                        );
                      }}
                    >
                      <FaArrowLeft className="mr-2 inline" title="Vissza" />
                      Vissza a megoldások listájára
                    </div>
                  </div>
                </div>
                <div className="text-5xl text-smart-black font-bold mt-[50px]">
                  <p className="">Szélturbina</p>
                </div>
                <div className="mt-4">
                  <p className="text-lg text-justify mt-8">
                    A szélturbina szélenergiát alakít át villamosenergiává
                    speciális elemeken (lapátok) keletkező légerők által
                    forgatott tengelyre kötött villamos generátor
                    alkalmazásával. A szélturbinák az egyik legelterjedtebb és
                    hatékonyabb módszerként szolgálnak a megújuló energia
                    előállítására. Ezek a berendezések általában nagy
                    magasságban elhelyezett toronyok tetején találhatók meg,
                    ahol a szélsebesség nagyobb. Létezik vízszintes és
                    függőleges tengelyű szélturbina, sőt hagyományos
                    turbinalapátok nélküli energiatermelő megoldás is. Fontos: a
                    háztartási energiairendszerekhez fejlesztett függőleges
                    szélturbinák zaja ma már kisebb, mint a széles körben
                    használt háztartási gépeké.
                  </p>
                </div>
                <div>
                  <p className="text-lg text-justify mt-8">
                    A szélturbina fő részei a következők: Rotorlapátok: Ezek a
                    lapátok a szélbe vannak helyezve, és amikor a szél fúj, a
                    lapátok elfordulnak. A forgó mozgást a szél energiájának
                    mechanikai energiává alakítják át. Generátor: A forgó mozgás
                    a generátort hajtja meg, amely elektromos áramot termel. A
                    generátor az elektromágneses indukció elvét használja fel a
                    mechanikai energiából elektromos áram előállítására. Torony:
                    A szélturbina toronya magas, hogy a rotorlapátok a magasabb
                    szélsebességekből profitálhassanak. A magasabb elhelyezkedés
                    javítja a hatékonyságot, mivel a szélsebesség a magasság
                    növekedésével általában nő.
                  </p>
                </div>
                <div>
                  <p className="text-lg text-justify mt-8">
                    A szélturbinákat szélfarmokban helyezik el, ahol több
                    turbinát összekapcsolva növelik az összesített elektromos
                    energia termelését. A szélenergia jelentős előnye, hogy
                    tiszta és megújuló energiaforrás, és kibocsátásmentesen
                    működik, nem járva szén-dioxid vagy más üvegházgázok
                    kibocsátásával.
                  </p>
                </div>
                <div>
                  <p className="text-lg text-justify mt-8">
                    A szélenergia jelentőségét növeli az is, hogy kiválóan
                    alkalmazkodik azokhoz a területekhez, ahol a szél viszonylag
                    erős és rendszeresen fúj. Sok országban és régióban
                    szélfarmok kiépítése része a fenntartható energiatermelés és
                    a klímaváltozás elleni küzdelem stratégiájának.
                  </p>
                </div>
                <div>
                  <p className="text-lg text-justify mt-8">
                    A geotermikus energia tiszta és megújuló energiaforrás,
                    amely környezetbarát és állandóan rendelkezésre álló
                    energiát nyújt. Azonban a hatékony hasznosításához
                    területspecifikus feltételek és beruházások szükségesek.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grow w-0"></div>
      </div>
    </div>
  );
};

export default Windturbine;
