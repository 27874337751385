import PageSection from "../page-section";
import PageSectionMargin from "../page-section/page-section-margin";
import PageSectionContent, {
  BodyWidth,
  Width,
} from "../page-section/page-section-content";
import imLogo from "../../../assets/footer/im-logo.png";
import mateLogo from "../../../assets/footer/mate-logo.png";
import { useNavigate } from "react-router-dom";
import routePaths from "../../constants/route-paths";
import Modal, { ModalState } from "../modal";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import TransitionModal from "../transition-modal";

const Footer = () => {
  const navigate = useNavigate();
  const [modalState, setModalState] = useState<ModalState>({ isOpen: false });

  return (
    <>
      <PageSection>
        <PageSectionMargin additionalClassName="bg-black"></PageSectionMargin>
        <PageSectionContent additionalClassName="bg-black">
          <div className="text-white">
            <div className="flex justify-between ml-[80px]">
              <div className="min-w-[216px]">
                <div className="text-smart-gray text-3xl font-[500] mb-4">
                  Menü
                </div>
                <div className="p-4">
                  <div
                    className="h-[48px] cursor-pointer"
                    onClick={() => navigate(routePaths.HOME)}
                  >
                    Főoldal
                  </div>
                  <div
                    className="h-[48px] cursor-pointer"
                    onClick={() => navigate(routePaths.OFFER)}
                  >
                    Ajánlás
                  </div>
                  <div
                    className="h-[48px] cursor-pointer"
                    onClick={() => navigate(routePaths.HOME_SOLUTIONS)}
                  >
                    Megoldások
                  </div>
                  <div
                    className="h-[48px] cursor-pointer"
                    onClick={() => navigate(routePaths.CONTRACTOR)}
                  >
                    Kivitelezők
                  </div>
                  <div
                    className="h-[48px] cursor-pointer"
                    onClick={() => navigate(routePaths.HOME_PROJECTINFO)}
                  >
                    Projekt információk
                  </div>
                  <div
                    className="h-[48px] cursor-pointer"
                    onClick={() => navigate(routePaths.HOME_ABOUTUS)}
                  >
                    Rólunk
                  </div>
                  <div
                    className="h-[48px] cursor-pointer"
                    onClick={() => navigate(routePaths.HOME_ENERGETICDEFS)}
                  >
                    Energetikai alapfogalmak
                  </div>
                  <div
                    className="h-[48px] cursor-pointer"
                    onClick={() => navigate(routePaths.HOME_TERMSOFUSE)}
                  >
                    Felhasználási feltételek
                  </div>
                  <div
                    className="h-[48px] cursor-pointer"
                    onClick={() => {
                      setModalState({
                        isOpen: true,
                      });
                    }}
                  >
                    Belépés regisztràlt felhasználóknak
                  </div>
                </div>
              </div>
              <div className="w-[370px] h-full">
                <div className="text-smart-gray text-3xl font-[500] mb-4">
                  Támogatók
                </div>
                <img
                  className="mt-[80px] cursor-pointer"
                  src={imLogo}
                  onClick={() => window.open(routePaths.IM, "_blank")}
                />
                <img
                  className="mt-[80px] cursor-pointer"
                  src={mateLogo}
                  onClick={() => window.open(routePaths.MATE, "_blank")}
                />
              </div>
            </div>
            <div className="flex justify-center">
              Minden jog fenntartva ® Magyar Agrár- és Élettudományi Egyetem
              2023
            </div>
          </div>
        </PageSectionContent>
        <PageSectionMargin additionalClassName="bg-black"></PageSectionMargin>
      </PageSection>
      <TransitionModal
        title="Információ"
        description="Ez a funkció később lesz elérhető"
        isOpen={modalState.isOpen}
        onClose={() => setModalState({ isOpen: false })}
      />
    </>
  );
};

export default Footer;
