import { LayoutPosition } from "chart.js";

export type ChartData = {
  labels: string[];
  datasets: [
    {
      label: string;
      data: number[];
      backgroundColor: string[];
      borderColor: string[];
      borderWidth: number;
    }
  ];
};

export const ShadowPlugin = {
  id: "shadow",
  beforeDraw: (chart: any, args: any, options: any) => {
    const { ctx } = chart;
    ctx.shadowColor = "rgba(0, 0, 0, 0.5)";
    ctx.shadowBlur = 10;
    ctx.shadowOffsetX = 3;
    ctx.shadowOffsetY = 3;
    ctx.bevelWidth = 5;
    ctx.bevelHighlightColor = "rgba(255, 255, 255, 0.75)";
    ctx.bevelShadowColor = "rgba(0, 0, 0, 0.5)";
    ctx.hoverInnerGlowWidth = 20;
    ctx.hoverInnerGlowColor = "rgb(255, 255, 0)";
    ctx.hoverOuterGlowWidth = 20;
    ctx.hoverOuterGlowColor = "rgb(255, 255, 0)";
  },
};

export const ChartOptions = {
  //padding: "0px",
  responsive: true,
  maintainAspectRatio: false,
  aspectRatio: 1,
  plugins: {
    legend: {
      position: "right" as LayoutPosition,
      display: false,
    },
    tooltip: {
      enabled: false,
    },
    animation: {
      animateScale: true,
      animateRotate: true,
    },
  },
  layout: {
    padding: 20,
  },
};

export const BackgroundColors: string[] = [
  "red",
  "blue",
  "gray",
  "green",
  "yellow",
  "rgba(255, 99, 132, 0.2)",
  "rgba(54, 162, 235, 0.2)",
  "rgba(255, 206, 86, 0.2)",
  "rgba(75, 192, 192, 0.2)",
  "rgba(153, 102, 255, 0.2)",
  "rgba(255, 159, 64, 0.2)",
];

export const BorderColors: string[] = [
  "rgba(255, 99, 132, 1)",
  "rgba(54, 162, 235, 1)",
  "rgba(255, 206, 86, 1)",
  "rgba(75, 192, 192, 1)",
  "rgba(153, 102, 255, 1)",
  "rgba(255, 159, 64, 1)",
];
