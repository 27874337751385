import { Dialog } from "@headlessui/react";
import { ReactNode, useRef } from "react";

export type ModalState = {
  isOpen: boolean;
  title?: string;
  content?: ReactNode;
  isPageHeaderHidden?: boolean;
};

export type ModalProps = {
  state: ModalState;
  setState: (value: ModalState) => void;
  children?: ReactNode;
};

const Modal = ({ state, setState }: ModalProps) => {
  let closeButtonRef = useRef(null);

  return (
    <Dialog
      open={state.isOpen}
      onClose={() => setState({ isOpen: false })}
      initialFocus={closeButtonRef}
      className="z-50 hidden sm:flex"
    >
      <div className="hidden sm:fixed sm:inset-0 sm:flex sm:w-screen sm:bg-black/30 sm:items-center sm:justify-center">
        <div
          className={
            "fixed inset-0 w-screen overflow-y-auto" +
            (state.isPageHeaderHidden === true ? "" : " mt-[100px]")
          }
        >
          <Dialog.Panel className="mx-auto max-w-4xl border-2 border-gray-300 divide-y-2 divide-gray-300 rounded-lg bg-white shadow-md">
            <Dialog.Title className="text-3xl text-center font-bold text-gray-600 p-4">
              {state.title}
            </Dialog.Title>
            <div className="text-lg text-justify p-4 space-y-2 text-gray-600">
              {state.content}
            </div>
            <div className="flex items-center justify-center text-xl p-4">
              <button
                ref={closeButtonRef}
                className="bg-lime-700 text-white font-bold rounded-md px-3 py-2"
                onClick={() => setState({ isOpen: false })}
              >
                Bezár
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};

export default Modal;
