import { createContext, Dispatch, Reducer, useEffect, useReducer } from "react";
import { OfferRequestModel } from "../../pages/offer/offer-request/models";
import { EnergySourceMixModel } from "../types/energySourceMixModel";

export interface OfferDataState {
  offerRequest: OfferRequestModel | undefined;
  offerResult: EnergySourceMixModel | undefined;
}

type Prop = {
  children: JSX.Element;
};

const initialState: OfferDataState = {
  offerRequest: undefined,
  offerResult: undefined,
};

export enum OfferDataActionType {
  setFormData,
}

export interface OfferDataAction {
  type: OfferDataActionType;
  params?: OfferDataState;
}

const offerDataReducer: Reducer<OfferDataState, OfferDataAction> = (
  prevState,
  action
) => {
  switch (action.type) {
    case OfferDataActionType.setFormData: {
      const state = action.params as OfferDataState;
      return {
        ...prevState,
        offerRequest: state.offerRequest,
        offerResult: state.offerResult,
      };
    }
    default:
      return prevState;
  }
};

export const OfferDataContext = createContext<{
  offerDataState: OfferDataState;
  dispatch: Dispatch<OfferDataAction>;
}>({
  offerDataState: initialState,
  dispatch: (_value) => {
    return;
  },
});

export const OfferDataProvider = ({ children }: Prop) => {
  const [offerDataState, dispatch] = useReducer(offerDataReducer, initialState);

  return (
    <OfferDataContext.Provider value={{ offerDataState, dispatch }}>
      {children}
    </OfferDataContext.Provider>
  );
};
