import React from "react";
import ProgressItem from "./progress-item";

type Props = {
  item: number;
};
const Progress = ({ item }: Props) => {
  return (
    <>
      <div className="w-[480px] pt-4 pr-4 pl-4">
        {item >= 1 && (
          <ProgressItem name="Paraméterek ellenőrzése"></ProgressItem>
        )}
        {item >= 2 && (
          <ProgressItem name="Földrajzi adatok gyűjtése"></ProgressItem>
        )}
        {item >= 3 && (
          <ProgressItem name="Kalkuláció folyamatban"></ProgressItem>
        )}
        {item >= 4 && (
          <ProgressItem name="A mesterséges intelligencia eredményeket számol"></ProgressItem>
        )}
        {item >= 5 && <ProgressItem name="Kalkuláció elkészült"></ProgressItem>}
      </div>
    </>
  );
};

export default Progress;
