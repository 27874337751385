import { useContext } from "react";
import { FaChevronRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import {
  HeaderPathActionType,
  HeaderPathContext,
} from "../../providers/header-path-context";
import routePaths from "../../constants/route-paths";

type Props = {
  title: string;
  image: string;
  url: string;
  portrait: boolean;
};

const SolutionCard = ({ title, image, url, portrait }: Props) => {
  const navigate = useNavigate();
  const { headerPathState, dispatch: headerPathDispatch } =
    useContext(HeaderPathContext);

  return portrait ? (
    <div
      className="flex flex-col  h-[320px] cursor-pointer transition hover:scale-105 duration-300"
      onClick={() => {
        headerPathDispatch({
          type: HeaderPathActionType.setPath,
          params: {
            path: routePaths.HOME_SOLUTIONS,
          },
        });
        navigate(url);
      }}
    >
      <div className="h-[160px]">
        <div className="flex grid grid-col-1 content-center h-[160px] bg-white gap-3">
          <div className="text-lg md:text-2xl lg:text-3xl text-black font-medium text-center">
            {title}
          </div>
          <div className="flex grid grid-col-1 content-center h-[40px] w-[40px] md:h-[44px] md:w-[44px] lg:h-[48px] lg:w-[48px] bg-[#91A500] rounded-full justify-self-center bg-gradient-to-r from-[#C3D200] to-[#91A500]">
            <FaChevronRight className="text-xl md:text-2xl lg:text-3xl text-white justify-self-center" />
          </div>
        </div>
      </div>
      <div className="h-[160px]">
        <img src={image} className="object-cover" />
      </div>
    </div>
  ) : (
    <div
      className="w-[640px] shrink-0 grow-0 h-[160px] cursor-pointer transition hover:scale-105 duration-300 mb-4"
      onClick={() => {
        navigate(url);
      }}
    >
      <div className="flex bg-white h-full border-[1px]">
        <div className="w-[240px] h-[160px]">
          <img src={image} className="w-full h-full object-cover" />
        </div>
        <div className="flex w-[400px] p-4">
          <div className="flex w-[350px] h-full justify-items-end">
            <div className="text-3xl text-black font-medium text-center">
              {title}
            </div>
          </div>
          <div className="flex h-full w-[50px] p-[2px] items-end justify-end">
            <div className="flex grid grid-col-1 content-center h-[48px] w-[48px] bg-[#91A500] rounded-full justify-self-center bg-gradient-to-r from-[#C3D200] to-[#91A500]">
              <FaChevronRight className="text-3xl text-white justify-self-center" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolutionCard;
