import React from "react";
import SVG from "react-inlinesvg";
import iconSvg from "../../assets/svg/check-light.svg";

type Props = {
  name: string;
};

const ProgressItem = ({ name }: Props) => {
  return (
    <div className="flex align-middle p-4">
      <div className="grid justify-items-end">
        <div className="box-border border-green-600 border-4 w-8 h-8 rounded-full align-middle bg-green-600">
          <img className="w-6 h-6" src={iconSvg} title="Kész" />
        </div>
      </div>
      <div className="ml-4 text-lg text-smart-darkgray">{name}</div>
    </div>
  );
};

export default ProgressItem;
