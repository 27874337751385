import * as Yup from "yup";

const requiredText = "A mezőt kötelező kitölteni";
const requiredGpsText = "A GPS koordináta kötelező";
const wrongValueText = "Az érték nem megfelelő";
const wrongGpsValueText = "A GPS értékhez használja az alábbi gombokat";

export const validationSchema = () =>
  Yup.object({
    longitude: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .min(-180, wrongGpsValueText)
      .max(180, wrongGpsValueText)
      .notOneOf([0], wrongGpsValueText)
      .required(requiredGpsText),
    latitude: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .notOneOf([0], wrongGpsValueText)
      .min(-90, wrongGpsValueText)
      .max(90, wrongGpsValueText)
      .required(requiredGpsText),
    hasGas: Yup.boolean().default(false),
    isExtended: Yup.boolean().default(false),
    usefulArea: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .when(["isExtended"], {
        is: (value: boolean) => value === true,
        then: (schema) =>
          schema
            .min(0, wrongValueText)
            .max(1000, wrongValueText)
            .required(requiredText),
        otherwise: (schema) => schema.optional(),
      }),
    energeticClass: Yup.string().when(["isExtended"], {
      is: (value: boolean) => value === true,
      then: (schema) =>
        schema
          .min(1, wrongValueText)
          .notOneOf(["none"], wrongValueText)
          .required(requiredText),
      otherwise: (schema) => schema.optional(),
    }),
    adults: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .when(["isExtended"], {
        is: (value: boolean) => value === true,
        then: (schema) =>
          schema
            .min(1, wrongValueText)
            .max(15, wrongValueText)
            .required(requiredText),
        otherwise: (schema) => schema.optional(),
      }),
    children: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .when(["isExtended"], {
        is: (value: boolean) => value === true,
        then: (schema) =>
          schema
            .min(0, wrongValueText)
            .max(15, wrongValueText)
            .required(requiredText),
        otherwise: (schema) => schema.optional(),
      }),
    winterInsideTemparature: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .when(["isExtended"], {
        is: (value: boolean) => value === true,
        then: (schema) =>
          schema
            .min(1, wrongValueText)
            .max(30, wrongValueText)
            .required(requiredText),
        otherwise: (schema) => schema.optional(),
      }),
    summerInsideTemperature: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .when(["isExtended"], {
        is: (value: boolean) => value === true,
        then: (schema) =>
          schema
            .min(1, wrongValueText)
            .max(30, wrongValueText)
            .required(requiredText),
        otherwise: (schema) => schema.optional(),
      }),
    electricCarEnergyRequirement: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .when(["isExtended", "energeticClass"], {
        is: (isExtended: boolean, energeticClass: any) =>
          isExtended === true && energeticClass === "unknown",
        then: (schema) => schema.min(0, wrongValueText).required(requiredText),
        otherwise: (schema) => schema.optional(),
      }),
    otherEnergyRequirement: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .when(["isExtended", "energeticClass"], {
        is: (isExtended: boolean, energeticClass: any) =>
          isExtended === true && energeticClass === "unknown",
        then: (schema) => schema.min(0, wrongValueText).required(requiredText),
        otherwise: (schema) => schema.optional(),
      }),
    wallType: Yup.string().when(["isExtended", "energeticClass"], {
      is: (isExtended: boolean, energeticClass: any) =>
        isExtended === true && energeticClass === "unknown",
      then: (schema) => schema.required(requiredText),
      otherwise: (schema) => schema.optional(),
    }),
    wallArea: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .when(["isExtended", "energeticClass"], {
        is: (isExtended: boolean, energeticClass: any) =>
          isExtended === true && energeticClass === "unknown",
        then: (schema) => schema.min(1, wrongValueText).required(requiredText),
        otherwise: (schema) => schema.optional(),
      }),
    windowType: Yup.string().when(["isExtended", "energeticClass"], {
      is: (isExtended: boolean, energeticClass: any) =>
        isExtended === true && energeticClass === "unknown",
      then: (schema) => schema.required(requiredText),
      otherwise: (schema) => schema.optional(),
    }),
    doorType: Yup.string().when(["isExtended", "energeticClass"], {
      is: (isExtended: boolean, energeticClass: any) =>
        isExtended === true && energeticClass === "unknown",
      then: (schema) => schema.required(requiredText),
      otherwise: (schema) => schema.optional(),
    }),
    roofType: Yup.string().when(["isExtended", "energeticClass"], {
      is: (isExtended: boolean, energeticClass: any) =>
        isExtended === true && energeticClass === "unknown",
      then: (schema) => schema.required(requiredText),
      otherwise: (schema) => schema.optional(),
    }),
    cellarType: Yup.string().when(["isExtended", "energeticClass"], {
      is: (isExtended: boolean, energeticClass: any) =>
        isExtended === true && energeticClass === "unknown",
      then: (schema) => schema.required(requiredText),
      otherwise: (schema) => schema.optional(),
    }),
    heatExchangerType: Yup.string().when(["isExtended", "energeticClass"], {
      is: (isExtended: boolean, energeticClass: any) =>
        isExtended === true && energeticClass === "unknown",
      then: (schema) => schema.required(requiredText),
      otherwise: (schema) => schema.optional(),
    }),
    shadingType: Yup.string().when(["isExtended", "energeticClass"], {
      is: (isExtended: boolean, energeticClass: any) =>
        isExtended === true && energeticClass === "unknown",
      then: (schema) => schema.required(requiredText),
      otherwise: (schema) => schema.optional(),
    }),
    windowAreaSouth: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .when(["isExtended", "energeticClass"], {
        is: (isExtended: boolean, energeticClass: any) =>
          isExtended === true && energeticClass === "unknown",
        then: (schema) => schema.min(0, wrongValueText).required(requiredText),
        otherwise: (schema) => schema.optional(),
      }),
    windowAreaNorth: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .when(["isExtended", "energeticClass"], {
        is: (isExtended: boolean, energeticClass: any) =>
          isExtended === true && energeticClass === "unknown",
        then: (schema) => schema.min(0, wrongValueText).required(requiredText),
        otherwise: (schema) => schema.optional(),
      }),
    doorAreaSouth: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .when(["isExtended", "energeticClass"], {
        is: (isExtended: boolean, energeticClass: any) =>
          isExtended === true && energeticClass === "unknown",
        then: (schema) => schema.min(0, wrongValueText).required(requiredText),
        otherwise: (schema) => schema.optional(),
      }),
    doorAreaNorth: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .when(["isExtended", "energeticClass"], {
        is: (isExtended: boolean, energeticClass: any) =>
          isExtended === true && energeticClass === "unknown",
        then: (schema) => schema.min(0, wrongValueText).required(requiredText),
        otherwise: (schema) => schema.optional(),
      }),
  });
