import SVG from "react-inlinesvg";
import backIcon from "../../../assets/solutions/back-icon-dark.svg";
import img from "../../../assets/solutions/geothermic.jpeg";
import { useNavigate } from "react-router-dom";
import routePaths from "../../../common/constants/route-paths";
import { useContext } from "react";
import { HeaderPathContext } from "../../../common/providers/header-path-context";
import { FaArrowLeft } from "react-icons/fa6";

const Solar = () => {
  const navigate = useNavigate();
  const { headerPathState, dispatch: headerPathDispatch } =
    useContext(HeaderPathContext);

  return (
    <div className="flex justify-center">
      <div className="w-full h-[560px] absolute">
        <img className="w-full min-h-[380px] max-h-[600px]" src={img} />
      </div>
      <div className="flex relative">
        <div className="flex w-full">
          <div className="grow w-0"></div>
          <div className="shrink w-[1024px] min-w-[640px] z-10 pt-[160px] pb-[80px]">
            <div className="flex border-[1px] border-smart-lightgray bg-smart-gray-white rounded-[4px] justify-center items-center pb-[80px]">
              <div className="w-[480px]">
                <div className="mt-[30px]">
                  <div className="flex items-center">
                    <div
                      className="inline-flex items-center cursor-pointer"
                      onClick={() => {
                        navigate(
                          headerPathState.path ?? routePaths.HOME_SOLUTIONS
                        );
                      }}
                    >
                      <FaArrowLeft className="mr-2 inline" title="Vissza" />
                      Vissza a megoldások listájára
                    </div>
                  </div>
                </div>
                <div className="text-5xl text-smart-black font-bold mt-[50px]">
                  <p className="">Napelem / napkollektor</p>
                </div>
                <div className="mt-4">
                  <p className="text-lg text-justify mt-8">
                    A napelem a napenergiát villamosenergiává alakító eszköz.
                    Lakossági mikro energetikai rendszerekben legtöbbet használt
                    (hazai viszonylatban a viszonylag sok és eléggé intenzív
                    napsugárzás miatt hatékonyan alkalmazható) napelemek
                    fejlesztése, gyártása ma is gyors. Ezek a fotovoltaikus
                    cellák, más néven napelemcellák, fényérzékeny anyagokat
                    használnak, amelyek a napfény hatására elektromos áramot
                    generálnak. A napelemek a megújuló energiaforrások egyik
                    formáját képviselik, mivel a nap energiáját közvetlenül
                    hasznosítják, hogy tiszta elektromos energiát állítsanak
                    elő.
                  </p>
                </div>
                <div>
                  <p className="text-lg text-justify mt-8">
                    A napelemcellák típusai között a legelterjedtebbek a
                    szilícium alapú cellák. Ezek az anyagok a napfény energiáját
                    közvetlenül árammá alakítják, és egy sor kapcsolódó cella
                    együttese alkotja a napelempanelt. A napelempanelek
                    telepítése után azok napfényben kezdenek elektromos energiát
                    termelni.
                  </p>
                </div>
                <div>
                  <p className="text-lg text-justify mt-8">
                    A napelemek számos előnnyel járnak: Megújuló Energiaforrás:
                    A napelemek a nap energiáját hasznosítják, ami egy megújuló
                    és kimerülhetetlen energiaforrás. Környezetbarát: A
                    napelemek üzemeltetése során nem keletkezik légszennyezés
                    vagy üvegházhatású gázok kibocsátása, ami környezetbarát
                    energiaforrást tesz belőlük. Hosszú Élettartam: A napelemek
                    hosszú élettartamúak és alacsony karbantartást igényelnek.
                    Energiaköltség-csökkentés: A napelemek telepítése
                    hozzájárulhat az energiafogyasztási költségek
                    csökkentéséhez, különösen hosszú távon. Függetlenség a
                    Hálózattól: Bizonyos rendszerek esetében a napelemek
                    lehetővé teszik az önfenntartó energiaellátást, függetlenül
                    a központi energiaellátó hálózattól.
                  </p>
                </div>
                <div>
                  <p className="text-lg text-justify mt-8">
                    A napelemeket széles körben alkalmazzák háztartásokban,
                    ipari létesítményekben és közintézményekben, valamint
                    hordozható eszközökben és űrhajózati alkalmazásokban is.
                    Azoknak az országoknak, városoknak és szervezeteknek,
                    amelyek az üvegházhatású gázok kibocsátásának csökkentésére
                    törekednek, a napelemek fontos eszközt jelentenek az
                    energiatermelés zöldítéséhez.
                  </p>
                </div>
                <div>
                  <p className="text-lg text-justify mt-8">
                    Napkollektor – fűtésre, vízmelegítésre használható
                    hőenergiát állít elő a napenergiából.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grow w-0"></div>
      </div>
    </div>
  );
};

export default Solar;
