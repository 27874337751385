export const style = {
  inputClass:
    "border border-lightgray relative mb-6 p-4 h-14 w-full rounded-[20px] text-smart-darkgray focus:outline-smart-green py-3",
  inputUnitClass:
    "border border-lightgray w-[65%] relative mb-6 p-4 h-14 w-full rounded-l-[20px] text-smart-darkgray focus:outline-smart-green",
  inputNearClass:
    "border border-lightgray mb-6 p-4 h-14 rounded-[20px] text-smart-darkgray focus:outline-smart-green",
  inputUnitNearClass:
    "border border-lightgray w-[64%] relative mb-6 p-4 h-14 w-full rounded-l-[20px] text-smart-darkgray focus:outline-smart-green",
  inputUnitNearClass2:
    "border border-lightgray w-[58%] relative mb-6 p-4 h-14 w-full rounded-l-[20px] text-smart-darkgray focus:outline-smart-green",
  checkBoxClass: "m-4 w-6 h-6 rounded text-smart-darkgray",
  labelValueClass: "mb-6 mb-1 text-smart-darkgray",
  labelClass: "text-smart-midgray mb-1 text-left",
  divClass: "flex items-center",
  locationClass:
    "flex border-[1px] border-smart-darkgray text-smart-darkgray font-weight-[500] text-lg text-smart-black w-[156px] h-[48px] rounded-l-[24px] items-center justify-center",
  mapClass:
    "flex border-[1px] border-smart-darkgray text-smart-darkgray font-weight-[500] text-lg text-smart-black w-[130px] h-[48px] rounded-r-[24px] items-center justify-center",
};
