import React from "react";
import OfferRequestForm from "./offer-request-form";
import { OfferRequestModel } from "./models";
import { isMobile } from "react-device-detect";

type Props = {
  handleFormSubmit: (data: OfferRequestModel) => void;
};

const OfferRequest = ({ handleFormSubmit }: Props) => {
  return (
    <div className="w-full">
      <div className="flex justify-center">
        <div
          className={
            isMobile
              ? "w-[440px] text-base text-smart-darkgray p-6 text-justify leading-[20px]"
              : "w-[440px] p-4 text-lg text-smart-darkgray text-justify"
          }
        >
          Az alábbi űrlap kitöltésével a rendszer energiafelhasználási ajánlatot
          ad önnek a megjelölt területen.A “További kérdések” gomb megnyomásával
          további mezőket tölthet ki, amelyek mind pontosítják az ajánlást.
          Minél több adatot ad meg, annál pontosabb ajánlást tesz a rendszer.
        </div>
      </div>
      <div className="flex justify-center w-[440px] ">
        <OfferRequestForm handleFormSubmit={handleFormSubmit} />
      </div>
    </div>
  );
};

export default OfferRequest;
