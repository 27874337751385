import { SolutionTypes } from "../../common/types/soluitionTypes";
import { ContractorSearchModel } from "./models";

export const initialValues: ContractorSearchModel = {
  longitude: 0,
  latitude: 0,
  isGas: false,
  isSolarPanel: false,
  isSolarCollector: false,
  isHeatPump: false,
  isWindTurbine: false,
  area: 10,
};
