import React from "react";
import { AppRoutes } from "./app-routes";
import { BrowserRouter as Router } from "react-router-dom";

import { GlobalProvider } from "../../common/providers/global-provider";
import { OfferDataProvider } from "../../common/providers/offer-data-context";
import { HeaderPathProvider } from "../../common/providers/header-path-context";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const App = () => {
  return (
    <GlobalProvider>
      <HeaderPathProvider>
        <OfferDataProvider>
          <Router>
            <AppRoutes />
          </Router>
        </OfferDataProvider>
      </HeaderPathProvider>
    </GlobalProvider>
  );
};

export default App;
