import SVG from "react-inlinesvg";
import backIcon from "../../../assets/solutions/back-icon-dark.svg";
import img from "../../../assets/solutions/geothermic.jpeg";
import { useNavigate } from "react-router-dom";
import routePaths from "../../../common/constants/route-paths";
import { useContext } from "react";
import { HeaderPathContext } from "../../../common/providers/header-path-context";
import { FaArrowLeft } from "react-icons/fa6";

const Geothermic = () => {
  const navigate = useNavigate();
  const { headerPathState, dispatch: headerPathDispatch } =
    useContext(HeaderPathContext);

  return (
    <div className="flex justify-center">
      <div className="w-full h-[560px] absolute">
        <img className="w-full min-h-[380px] max-h-[600px]" src={img} />
      </div>
      <div className="flex relative">
        <div className="flex w-full">
          <div className="grow w-0"></div>
          <div className="shrink w-[1024px] min-w-[640px] z-10 pt-[160px] pb-[80px]">
            <div className="flex border-[1px] border-smart-lightgray bg-smart-gray-white rounded-[4px] justify-center items-center pb-[80px]">
              <div className="w-[480px]">
                <div className="mt-[30px]">
                  <div className="flex items-center">
                    <div
                      className="inline-flex items-center cursor-pointer"
                      onClick={() => {
                        navigate(
                          headerPathState.path ?? routePaths.HOME_SOLUTIONS
                        );
                      }}
                    >
                      <FaArrowLeft className="mr-2 inline" title="Vissza" />
                      Vissza a megoldások listájára
                    </div>
                  </div>
                </div>
                <div className="text-5xl text-smart-black font-bold mt-[50px]">
                  <p className="">Geotermikus</p>
                </div>
                <div className="mt-4">
                  <p className="text-lg text-justify mt-8">
                    A geotermikus energia a Föld belső hőmérsékletéből származó
                    energiaforrás, amelyet általában a geotermikus hőszivattyúk
                    vagy geotermikus erőművek használnak fel, és amelyek hő,
                    vagy villamos energiát biztosítanak. A Föld belsejében a
                    radioaktív bomlásból és a földkéreg mozgásából adódó hő
                    termelődik. A geotermikus energia kihasználása különböző
                    formákban történhet:
                  </p>
                </div>
                <div>
                  <p className="text-lg text-justify mt-8">
                    Geotermikus Hőszivattyúk: A geotermikus hőszivattyúk a Föld
                    felszíne alatt található hőt használják fel a házak és
                    épületek fűtésére vagy hűtésére. Ezek a rendszerek
                    működésükhöz használják a talaj vagy a víz hőjét, amelyet
                    egy hűtőközeg szállít az épület belsejébe.
                  </p>
                </div>
                <div>
                  <p className="text-lg text-justify mt-8">
                    Geotermikus Erőművek: A geotermikus erőművek nagyobb
                    méretekben használják a Föld hőjét az elektromos energia
                    előállításához. A geotermikus energiaforrások közvetlenül a
                    felszín alatt vagy a geotermikus forrásokból (gejzírek,
                    termálforrások) nyerik a hőt, amelyet aztán használnak a
                    gőztermelésre, ami mozgatja a turbina-generátor rendszert.
                  </p>
                </div>
                <div>
                  <p className="text-lg text-justify mt-8">
                    Direkt Geotermikus Hasznosítás: Néhány területen a
                    geotermikus energiaforrások közvetlenül hasznosulnak,
                    például termálvizes fürdők, mocsarak vagy hőfürdők
                    formájában. Az ilyen források természetes módon kihasználják
                    a Föld felszínén található melegvizű területeket.
                  </p>
                </div>
                <div>
                  <p className="text-lg text-justify mt-8">
                    A geotermikus energia tiszta és megújuló energiaforrás,
                    amely környezetbarát és állandóan rendelkezésre álló
                    energiát nyújt. Azonban a hatékony hasznosításához
                    területspecifikus feltételek és beruházások szükségesek.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grow w-0"></div>
      </div>
    </div>
  );
};

export default Geothermic;
