import { GoogleMap, Marker } from "@react-google-maps/api";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  OfferDataActionType,
  OfferDataContext,
} from "../../common/providers/offer-data-context";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../common/components/header";
import { OfferRequestModel } from "../offer/offer-request/models";
import { isMobile } from "react-device-detect";
import { initialValues } from "../offer/offer-request/service";

const mapContainerStyle = {
  height: "100%",
  width: "100%",
};

const options = {
  disableDefaultUI: true,
  zoomControl: true,
};

const center = {
  lat: 47.497913,
  lng: 19.040236,
};

export const Map = () => {
  const navigate = useNavigate();
  const mapRef = useRef<google.maps.Map>();

  const { offerDataState, dispatch } = useContext(OfferDataContext);

  const [marker, setMarker] = useState<{ lat: number; lng: number }>();

  const onMapLoad = useCallback((map: google.maps.Map) => {
    mapRef.current = map;
  }, []);

  const { state } = useLocation();
  const { returnUrl, lat, lng } = state;

  const setPosition = (lat: number, lng: number) => {
    if (lat && lng) {
      mapRef.current?.panTo({
        lat: lat,
        lng: lng,
      });
      mapRef.current?.setZoom(14);
    }
  };

  useEffect(() => {
    if (
      offerDataState.offerRequest?.latitude &&
      offerDataState.offerRequest?.longitude
    ) {
      setMarker({
        lat: offerDataState.offerRequest!.latitude,
        lng: offerDataState.offerRequest!.longitude,
      });
    }
  }, [offerDataState]);

  useEffect(() => {
    setMarker({ lat, lng });
  }, [lat, lng]);

  const panTo = useCallback(({ lat, lng }: { lat: number; lng: number }) => {
    setPosition(lat, lng);
  }, []);

  const handleOkClick = () => {
    const form = offerDataState.offerRequest ?? initialValues;
    if (marker) {
      dispatch({
        type: OfferDataActionType.setFormData,
        params: {
          offerRequest: {
            ...form,
            longitude: marker.lng,
            latitude: marker.lat,
          },
          offerResult: undefined,
        },
      });
    }
    navigate(returnUrl);
  };

  const handleCancelClick = () => {
    navigate(returnUrl);
  };

  function Locate() {
    return (
      <button
        className="mb-2 p-2 border-smart-lightgreen rounded-[4px] text-smart-white bg-smart-lightgreen"
        onClick={() => {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              setMarker({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              });
            },
            () => null
          );
        }}
      >
        Aktuális pozíció
      </button>
    );
  }

  return (
    <>
      <div className="bg-smart-black text-smart-white">
        <div className="p-4 w-[100%] h-[1000px]">
          <div className="flex">
            <div className="w-full">
              <Locate />
              <label className="m-2 p-2">
                {marker?.lat}, {marker?.lng}
              </label>
            </div>
            <div className="flex w-full justify-end">
              <button
                className="mb-2 p-2 border-smart-lightgreen rounded-[4px] text-smart-white bg-smart-lightgreen m-2"
                onClick={handleOkClick}
              >
                Mentés
              </button>
              <button
                className="mb-2 p-2 border-smart-lightgreen rounded-[4px] text-smart-white bg-smart-lightgreen m-2"
                onClick={handleCancelClick}
              >
                Mégsem
              </button>
            </div>
          </div>
          {/* <div className="w-[100%] h-[100%]"> */}
          <GoogleMap
            id="map"
            mapContainerStyle={mapContainerStyle}
            zoom={8}
            center={center}
            options={options}
            onLoad={onMapLoad}
            onClick={(e) => {
              const lat = e.latLng?.lat();
              const lng = e.latLng?.lng();
              if (lat && lng) {
                setMarker({ lat: lat, lng: lng });
              }
            }}
          >
            {marker && <Marker position={marker} />}
          </GoogleMap>
        </div>
      </div>
    </>
  );
};
