import { createContext, Dispatch, Reducer, useEffect, useReducer } from "react";

export interface HeaderPathState {
  path: string | undefined;
}

type Prop = {
  children: JSX.Element;
};

const initialState: HeaderPathState = {
  path: undefined,
};

export enum HeaderPathActionType {
  setPath,
}

export interface HeaderPathAction {
  type: HeaderPathActionType;
  params?: HeaderPathState;
}

const headerPathReducer: Reducer<HeaderPathState, HeaderPathAction> = (
  prevState,
  action
) => {
  switch (action.type) {
    case HeaderPathActionType.setPath: {
      const state = action.params as HeaderPathState;
      return {
        ...prevState,
        path: state.path,
      };
    }
    default:
      return prevState;
  }
};

export const HeaderPathContext = createContext<{
  headerPathState: HeaderPathState;
  dispatch: Dispatch<HeaderPathAction>;
}>({
  headerPathState: initialState,
  dispatch: (_value) => {
    return;
  },
});

export const HeaderPathProvider = ({ children }: Prop) => {
  const [headerPathState, dispatch] = useReducer(
    headerPathReducer,
    initialState
  );

  return (
    <HeaderPathContext.Provider value={{ headerPathState, dispatch }}>
      {children}
    </HeaderPathContext.Provider>
  );
};
