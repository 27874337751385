import React, { useContext } from "react";
import { Libraries } from "@react-google-maps/api";
import { Map } from "./map";
import { GlobalContext } from "../../common/providers/global-provider";

const libraries = ["places"] as Libraries;

const MapLoader = () => {
  const { state } = useContext(GlobalContext);

  const isLoaded = state.mapIsLoaded;

  return isLoaded ? <Map /> : null;
};
export default MapLoader;
