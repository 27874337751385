import React, { useContext } from "react";
import ContractorForm from "./contractors-form";
import { useLocation } from "react-router-dom";
import { ContractorSearchModel } from "./models";
import { GlobalContext } from "../../common/providers/global-provider";
import contractorImg from "../../assets/jpg/contractors.jpg";

const Contractor = () => {
  const { state } = useLocation();
  const { state: globalState } = useContext(GlobalContext);
  const isLoaded = globalState.mapIsLoaded;

  const model = state ? (state as ContractorSearchModel) : undefined;
  console.log("state", state);
  return (
    <div className="flex-1">
      <div className="flex justify-center relative">
        <div className="h-[560px] absolute">
          <img
            className="w-full min-h-[380px] max-h-[600px]"
            src={contractorImg}
          />
        </div>
        <div className="flex w-full">
          <div className="grow w-0"></div>
          <div className="shrink w-[1024px] min-w-[640px] z-10 pt-[80px] pb-[80px]">
            <div className="border-[1px] border-smart-lightgray bg-smart-gray-white rounded-[4px]">
              {isLoaded ? <ContractorForm model={model} /> : null}
            </div>
          </div>
          <div className="grow w-0"></div>
        </div>
      </div>
    </div>
  );
};

export default Contractor;
