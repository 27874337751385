import { detailStyles } from "./detail-styles";

export const functionalBasicsContent = {
    title: "Működési alapok - módszertan",
    body: (
        <>
            <p>
                <span className={detailStyles.highlightStrong}>Működési koncepció</span> adott rendszer, berendezés, eszköz működésének a leírása/ismertetése a
                felhasználó szempontjából.
            </p>

            <p>
                <span className={detailStyles.highlightStrong}>Használati utasítás</span> a rendszer, berendezés, eszköz biztonságos és hatékony működését
                biztosítandó követendő használati, cselekvési, eljárási (rend) leírása.
            </p>

            <p>
                <span className={detailStyles.highlightStrong}>Jellemző</span> itt, a rendszer, berendezés, eszköz működésére, használatára vonatkozó alapvető
                tulajdonság, jellemző.
            </p>

            <p>
                <span className={detailStyles.highlightStrong}>Kapacitás</span> a rendszer, berendezés, eszköz, egységéhez, vagy adott időegységhez között
                jellemzője. Itt pl. a termelési kapacitás (mint adott idő alatt termelt napelemek száma), tárol energia mennyisége (energia egység egyegységnyi tömegű
                anyagban, pl. akkumulátorcsomagban), stb.
            </p>

            <p>
                <span className={detailStyles.highlightStrong}>Hatásfok</span> itt a rendelkezésre álló energia felhasználásának a mértéke (ténylegesen
                felhasznált per rendelkezésre álló / előállított energia).
            </p>

            <p>
                <span className={detailStyles.highlightStrong}>Hatékonyság</span> az eredmény /kibocsátás és a ráfordítás viszonya. Itt pl. a mikor energetikai
                rendszerben termelt és felhasznált energiamennyiség költsége.
            </p>

            <p>
                <span className={detailStyles.highlightStrong}>Adatbázisok</span> statisztikai hivatalok, szervezetek, adatbankok által közzétett adathalmazok.
                Itt pl. a meteorológiai szoláglat hőmérsékleti, csapadék, szél, stb. adatai az idő és a hely függvényébe.
            </p>

            <p>
                <span className={detailStyles.highlightStrong}>Adatbázisok felbontási képességének a javítása</span> az adatbázisok adott felbontási szintre
                vonatkoznak (pl. 10 x 10 km –es hálózat csomópontjaira), melyet az okos térkép alkalmazásához fel kellett javítani (lehetőleg 100 x 100 méteres szintig).
                (Az adott projektben approximációs, analóg (hasonlóság)  követés és mesterséges intelligencia alapú javításokat alkalmaztak a fejlesztők.)
            </p>

            <p>
                <span className={detailStyles.highlightStrong}>Szabályozás (szabályozási politika)</span> a gazdasági, társadalmi érdekeket összehangolva a
                biztonság elvárt szinten tartása és a hosszabb stratégiai célok elérése érdekében végrehajtandó  beavatkozások. Itt pl. az építési előírások, a piaci
                támogatási formák, stb. meghatározása.
            </p>

            <p>
                <span className={detailStyles.highlightStrong}>Épületek energetikai tanúsítványa</span> olyan igazoló okirat, mely az adott épületre
                (annak egységére) jogszabályban előirt eljárás alapján meghatározott energetikai teljesítő képességét adja meg. A tanúsítvány a közérthetőség
                szempontjából (a háztartási gépeknél már megszokott módon) energetikai osztályokba (AA, BB, stb.) sorolva jellemzi az épületet. A valóságban az
                osztályozás az épület hasznos területének egy négyzetméterére vonatkoztatva adja meg az éves energiaveszteséget, kWh/év/m2 formában.
            </p>

            <p>
                <span className={detailStyles.highlightStrong}>Tudományos, technológiai jellemzők</span> a tudományos, technológiai fejlődés hatásait, mint
                tulajdonságokat leíró ismeretek. Itt pl. az egységnyi felületű napelem által termelt maximális energia, vagy a termelt energia költsége.
            </p>

            <p>
                <span className={detailStyles.highlightStrong}>Gazdasági, piaci elvárások</span> a gazdasági és a piac által preferált jellemzők.
            </p>

            <p>
                <span className={detailStyles.highlightStrong}>Társadalmi elvárások</span> a társadalom által preferált jellemzők. Itt a társadalom által elvárt
                árak, a minimális környezeti terhelés, stb. értelmezése választott jellemzőkkel.
            </p>

            <p>
                <span className={detailStyles.highlightStrong}>Energiaigény becslés</span> (ebben az okos térkép projektben) olyan eljárás, mely alkalmas arra,
                hogy a valós geográfiai, meteorológiai viszonyok, az épület szerkezeti, energetikai jellemzői, az épített környezet, az épület valós alkalmazási viszonyai
                (hányan élnek ott, vagy csak dolgoznak ott) függvényében az éves energiaigény meghatározása. (Az épület energetikai tanúsítvány alapján meghatározható
                energiaigény módosítása a valós körülmények, pl. az adott helyszínen a téli, nyári hőmérsékleti viszonyok, az épület elhelyezkedése az uralkodó széljárás
                és a szélerősség , stb. függvényében.)
            </p>

            <p>
                <span className={detailStyles.highlightStrong}>Energiaigény előrejelzése</span> az energiaigény várható változását, a rendszer esetleges „romlási”
                folyamatait, és a finanszírozási lehetőségeket, továbbá a tervezett épület modernizálást, az épülethasználatban bekövetkező változásokat számításba vevő
                becslése a következő (6-10) évekre
            </p>

            <p>
                <span className={detailStyles.highlightStrong}>Támogatás</span> a gazdasági, társadalmi elvárások és a szabályozási politika alapján meghatározott
                hitele, vagy vissza nem térintendő anyagi támogatása
            </p>

            <p>
                <span className={detailStyles.highlightStrong}>Fajlagos élettartamciklus költsége</span> a termelt, vagy felhasznált energia egységére vonatkozó
                költség becslés, mely minden, a rendszer 8-12 éves használata alapján felmerül. A jelentősebb költségfajták, a felhasznált elemek, anyagok beszerzése, a
                rendszer (itt pl. a napelemes) kiépítése, a működtetés, a karbantartás, javítás öüsszes költsége, mínusz a berendezések, rendszerelemek maradvány költsége
                (esetleg pluszként a nem értékesíthetőp maradvány újrahasznosítás költsége). A költséget kWh/Ft-ban lehet megadni, amit pl. a napelemes rendszer esetében
                külön számítani lehet a napelemes alrendszerre, vagy a napelemes alrendszer és a  hálózati energiahasználat során az össze energiafelhasználásra.
                (Ez utóbbi esetben a jó napelemes rendszer alkalmazásával a teljes energiaköltségnek csökkennie kell.)
            </p>

            <p>
                <span className={detailStyles.highlightStrong}>Fajlagos élettartamciklus hatása</span> a fajlagos élettartamciklus költségének a számítási elvét
                alkalmazva bármilyen más hatás, pl. a környezet terhelés, az üvegház hatású gázok kibocsátása is számítható.
            </p>

            <p>
                <span className={detailStyles.highlightStrong}>Optimálási feladat</span> valamely cél (jellemzően minimális fajlagos élettartamcdetailStylesklus költség,
                vagy minimális környezetterhelés) elérése érdekében elérendő feladat. Jelen okos térkép fejlesztésekor az optimalizáció a többféle energia
                forrásalkalmazásával elérhető energia termelési /felhasználási mix olyan részarányainak a meghatározása, mely biztosítja a költséghatékonyságot.
                Az optimálási feladat lényegében az előrejelzett energiaigény (ez az un célfüggvény) megfelelő kielégítését jelenti a korlátozó feltételek mellett.
                A korlátozó feltételek lehetnek műszaki, technológia, gazdasági, vagy környezeti feltételek (pl. a szél átlagos értékének meg kell haladni a 25-3 m/s
                értéket és a napi 3-4 órát ahhoz, hogy hatékonyan alkalmazható legyen).
            </p>

            <p>
                <span className={detailStyles.highlightStrong}>Optimálási eljárás</span> olyan eljárás mely alkalmazásával az optimálási feladat megoldható.
                Nagyon sok féle eljárás létezik. Jelen okos térkép mesterséges intelligencia alapú megoldást használ.
            </p>

            <p>
                <span className={detailStyles.highlightStrong}>Adaptív rendszer</span> a változó feltételekhez alkalmazkodó rendszer. Jelen okos térkép projekt
                során kialakított weblap alapú szolgáltatás is ilyen. rendszeres időszakonként elemzi a technológia, gazdasági, piaci (politikai) szabályozási
                változásokat és az alkalmazott adathalmazokat pontosítja, a felhasznált (számítási) modellek paramétereit újra becskli és a változásokhoz igazítja.
            </p>

            <p>
                <span className={detailStyles.highlightStrong}>Okos rendszer</span> olyan rendszer, mely érzékelők adatait feldolgozva, kiértékelve, az előzetesen
                elvárt / programozott módon képes beavatkozni és irányítani saját magát. Pl. a fűtési hőmérsékletet attól függően változtatva hány embert érzékel a
                rendszer az adott helyiségben, akár azt is értékelve, milyen tevékenységet folytatnak a helységben lévők.
            </p>

            <p>
                <span className={detailStyles.highlightStrong}>Mesterséges intelligencia</span> szűkebb értelembe olyan gép, vagy gépi program, melyet mesterségesen
                előállított tudat irányít. Tágabb értelemben olyan megoldások követése, mely hasonlóan működik, mint az emberi szituáció felismerés-értékelés-döntés
                kialakítása. Az okos térkép projektben a fejlesztők az un. neurális hálót alkalmazták, melyt az emberi agy működését modellezi. A mesterséges
                intelligenciák alkalmazását mindig egy betanítási folyamattal lehet felkészíteni az alkalmazásra.
            </p>

            <p>
                <span className={detailStyles.highlightStrong}>Intelligens rendszer</span> olyan fejlett mesterséges intelligenciát tartalmazó rendszer, mely képes
                új szituációkat felismerni, változásokat értékelni és az új helyzethez igazodni.
            </p>
        </>
    )
};