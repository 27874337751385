import { CSSProperties, ReactNode } from "react";

type Props = {
    children?: ReactNode;
    additionalClassName?: string;
    style?: CSSProperties;
};

const PageSectionMargin = ({ children, additionalClassName, style }: Props) =>
{
    return (
        <div className={additionalClassName ? "grow w-0 " + additionalClassName : "grow w-0"} style={style}>
            {children}
        </div>
    );
};

export default PageSectionMargin;
