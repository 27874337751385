import SVG from "react-inlinesvg";
import backIcon from "../../../assets/solutions/back-icon-dark.svg";
import img from "../../../assets/solutions/geothermic.jpeg";
import { useNavigate } from "react-router-dom";
import routePaths from "../../../common/constants/route-paths";
import { useContext } from "react";
import { HeaderPathContext } from "../../../common/providers/header-path-context";
import { FaArrowLeft } from "react-icons/fa6";

const Heatpump = () => {
  const navigate = useNavigate();
  const { headerPathState, dispatch: headerPathDispatch } =
    useContext(HeaderPathContext);

  return (
    <div className="flex justify-center">
      <div className="w-full h-[560px] absolute">
        <img className="w-full min-h-[380px] max-h-[600px]" src={img} />
      </div>
      <div className="flex relative">
        <div className="flex w-full">
          <div className="grow w-0"></div>
          <div className="shrink w-[1024px] min-w-[640px] z-10 pt-[160px] pb-[80px]">
            <div className="flex border-[1px] border-smart-lightgray bg-smart-gray-white rounded-[4px] justify-center items-center pb-[80px]">
              <div className="w-[480px]">
                <div className="mt-[30px]">
                  <div className="flex items-center">
                    <div
                      className="inline-flex items-center cursor-pointer"
                      onClick={() => {
                        navigate(
                          headerPathState.path ?? routePaths.HOME_SOLUTIONS
                        );
                      }}
                    >
                      <FaArrowLeft className="mr-2 inline" title="Vissza" />
                      Vissza a megoldások listájára
                    </div>
                  </div>
                </div>
                <div className="text-5xl text-smart-black font-bold mt-[50px]">
                  <p className="">Hőszivattyú</p>
                </div>
                <div className="mt-4">
                  <p className="text-lg text-justify mt-8">
                    A hőszivattyú a háztartási hűtőgépek működéséhez hasonló
                    eszköz, mely az egyik helyről (a földből, vízből, külső
                    levegőből) hőt visz át egy másik helyre (pl. a belső terek
                    fűtésére). A működése hasonló ahhoz, mint egy hűtőszekrényé,
                    de működhet mind fűtésre, mind hűtésre. A hőszivattyú képes
                    hőt átvinni egy hidegebb területről egy melegebb területre,
                    még akkor is, ha a külső hőmérséklet hideg. Ez azért
                    lehetséges, mert a hőszivattyú nem termel hőt, hanem a már
                    létező hőenergiát mozgatja.
                  </p>
                </div>
                <div>
                  <p className="text-lg text-justify mt-8">
                    Fontos: a hőszivattyúk egységnyi (villamos) energia
                    felhasználásával annál 3-5-ször több hőenergiát képest
                    átvinne egyik helyről a másikra, vagyis egység energiát
                    felhasználva a 3-5 egységnyi energiával „tud” fűteni.
                  </p>
                </div>
                <div>
                  <p className="text-lg text-justify mt-8">
                    A hőszivattyúk három fő típusa létezik: Levegő-Levegő
                    Hőszivattyú: Ez a típusú hőszivattyú a kültéri levegőből
                    vonja el a hőt, majd azt átviszi a benti térbe fűtés
                    céljából. A légkondicionáló készülékek is hasonló elven
                    működnek, de képesek inverz módban is működni, tehát hűtésre
                    is alkalmasak. Levegő-Víz Hőszivattyú: Ebben az esetben a
                    kültéri levegő hőjét egy vízrendszeren keresztül
                    továbbítják, amely fűtést vagy meleg vizet biztosít a házban
                    vagy más épületben. Víz-Víz Hőszivattyú: Ez a típus a föld
                    alatti vagy felszíni vízből nyeri ki a hőt, majd azt egy
                    vízrendszeren keresztül használja fel.
                  </p>
                </div>
                <div>
                  <p className="text-lg text-justify mt-8">
                    A hőszivattyúk hatékony energiaforrásként szolgálnak, mivel
                    a hő mozgatásához elektromos energia csak kis mennyiségűre
                    van szükség. Emellett környezetbarát módon üzemelnek,
                    kibocsátva kevesebb szén-dioxidot, mint a hagyományos fűtési
                    rendszerek. A hőszivattyúk különféle helyzetekben
                    alkalmazhatók, beleértve a lakóházak, ipari létesítmények és
                    üzletek fűtését és hűtését.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grow w-0"></div>
      </div>
    </div>
  );
};

export default Heatpump;
