import React, { useContext, useEffect, useState } from "react";
import routePaths from "../../common/constants/route-paths";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  ContractorSearchModel,
  ContractorSearchResultListModel,
  ContractorSearchResultModel,
} from "./models";
import { initialValues } from "./service";
import { style } from "./config";
import { labelValues } from "../../common/constants/label-texts";
import FormError from "../../common/components/form-error";
import mapSvg from "../../assets/svg/map-icon-dark.svg";
import locationSvg from "../../assets/svg/location-icon-dark.svg";
import {
  OfferDataActionType,
  OfferDataContext,
} from "../../common/providers/offer-data-context";
import UnitBox from "../../common/components/unit";
import ContractorCard from "./contractor-card";
import Header from "../../common/components/header";
import { isMobile } from "react-device-detect";
import {
  HeaderPathActionType,
  HeaderPathContext,
} from "../../common/providers/header-path-context";
import { post } from "../../common/api/api.service";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./validation";

type Props = {
  model: ContractorSearchModel | undefined;
};

const ContractorsForm = ({ model }: Props) => {
  const { offerDataState, dispatch } = useContext(OfferDataContext);
  const { headerPathState, dispatch: headerPathDispatch } =
    useContext(HeaderPathContext);

  const [isOpen, setIsOpen] = useState(true);

  const {
    inputClass,
    inputUnitClass,
    checkBoxClass,
    checkBoxListClass,
    labelClass,
    checkboxLabelClass,
    locationClass,
    mapClass,
  } = style;

  const {
    reset,
    register,
    handleSubmit,
    getValues,
    setValue: setFormValue,
    formState: { errors },
  } = useForm<ContractorSearchModel>({
    defaultValues: model ?? initialValues,
    resolver: yupResolver(validationSchema()),
  });

  const navigate = useNavigate();

  const [showCoordinates, setShowCoordinates] = useState(false);

  useEffect(() => {
    if (offerDataState.offerRequest) {
      reset({
        longitude: offerDataState.offerRequest.longitude,
        latitude: offerDataState.offerRequest.latitude,
      });
    }
  }, [offerDataState]);

  const getRequestData = (src: ContractorSearchModel) => {
    return {
      latitude: src.latitude,
      longitude: src.longitude,
      distanceRange: src.area,
      isGas: src.isGas,
      isSolarPanel: src.isSolarPanel,
      isSolarCollector: src.isSolarCollector,
      isHeatPump: src.isHeatPump,
      isWindTurbine: src.isWindTurbine,
    };
  };

  const [resultData, setResultData] =
    useState<Array<ContractorSearchResultModel>>();

  const onSubmit: SubmitHandler<ContractorSearchModel> = async (formData) => {
    const result = await post<ContractorSearchResultListModel>(
      "search-contractors",
      getRequestData(formData),
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "post",
        },
      }
    );

    if (result && result.hitGroups.length > 0) {
      setResultData(result.hitGroups);
    } else {
      console.log("Nincs eredménye a keresésnek");
      setResultData([
        {
          name: "Nem található a feltételnek megfelelő szolgáltató az adatbázisban.",
          hits: [],
        },
      ]);
    }
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setShowCoordinates(true);
        setFormValue("latitude", position.coords.latitude);
        setFormValue("longitude", position.coords.longitude);
      });
    }
  };

  return (
    <>
      <form
        className="flex flex-col pt-6 pb-2 min-w-[480px] text-lgx font-medium text-smart-darkgray justify-center items-center"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex w-full border-b-[1px] border-smart-lightgray justify-center items-center text-base text-left">
          <div className="w-[440px] mb-3">
            <div className="text-lgxl text-smart-darkgray font-medium mb-5">
              Kivitelezők
            </div>
            {/* <label className={labelClass}>{getValues().address}</label> */}
            {isOpen && (
              <>
                <div className="relative">
                  <label className={labelClass}>{labelValues.latitude}</label>
                  <input className={inputClass} {...register("latitude")} />
                  <FormError errors={errors} errorKey={"latitude"} />
                </div>
                <div className="relative">
                  <label className={labelClass}>{labelValues.longitude}</label>
                  <input className={inputClass} {...register("longitude")} />
                  <FormError errors={errors} errorKey={"longitude"} />
                </div>
                <div className="flex mb-8 mt-2 justify-center items-center">
                  <div
                    role="button"
                    className={locationClass}
                    onClick={getLocation}
                  >
                    <img
                      className="w-6 h-6 mr-2"
                      src={locationSvg}
                      title="Aktuális pozíció"
                    />
                    Helyzetem
                  </div>
                  <div
                    role="button"
                    className={mapClass}
                    onClick={() => {
                      dispatch({
                        type: OfferDataActionType.setFormData,
                        params: {
                          offerRequest: offerDataState.offerRequest,
                          offerResult: offerDataState.offerResult,
                        },
                      });
                      headerPathDispatch({
                        type: HeaderPathActionType.setPath,
                        params: {
                          path: routePaths.CONTRACTOR,
                        },
                      });
                      navigate(routePaths.MAP, {
                        state: {
                          returnUrl: routePaths.CONTRACTOR,
                          lat:
                            offerDataState.offerRequest?.latitude ??
                            getValues()?.latitude,
                          lng:
                            offerDataState.offerRequest?.longitude ??
                            getValues()?.longitude,
                        },
                      });
                    }}
                  >
                    <img className="w-6 h-6 mr-2" src={mapSvg} title="Térkép" />
                    Térkép
                  </div>
                </div>
                <div className="my-4">
                  <div key="gas" className="relative">
                    <div className="flex">
                      <div className="">
                        <input
                          type="checkbox"
                          className={checkBoxListClass}
                          {...register("isGas")}
                        ></input>
                        <FormError errors={errors} errorKey={"isGas"} />
                      </div>
                      <label className={checkboxLabelClass}>gázvezeték</label>
                    </div>
                  </div>
                  <div key="solar" className="relative">
                    <div className="flex">
                      <div className="">
                        <input
                          type="checkbox"
                          className={checkBoxListClass}
                          {...register("isSolarPanel")}
                        ></input>
                        <FormError errors={errors} errorKey={"isSolarPanel"} />
                      </div>
                      <label className={checkboxLabelClass}>napelem</label>
                    </div>
                  </div>
                  <div key="collector" className="relative">
                    <div className="flex">
                      <div className="">
                        <input
                          type="checkbox"
                          className={checkBoxListClass}
                          {...register("isSolarCollector")}
                        ></input>
                        <FormError
                          errors={errors}
                          errorKey={"isSolarCollector"}
                        />
                      </div>
                      <label className={checkboxLabelClass}>napkollektor</label>
                    </div>
                  </div>
                  <div key="heatpump" className="">
                    <div className="flex">
                      <div className="">
                        <input
                          type="checkbox"
                          className={checkBoxListClass}
                          {...register("isHeatPump")}
                        ></input>
                      </div>
                      <label className={checkboxLabelClass}>hőszivattyú</label>
                    </div>
                  </div>
                  <div key="windTurbine" className="">
                    <div className="flex">
                      <div className="">
                        <input
                          type="checkbox"
                          className={checkBoxListClass}
                          {...register("isWindTurbine")}
                        ></input>
                      </div>
                      <label className={checkboxLabelClass}>szélturbina</label>
                    </div>
                  </div>
                </div>
                <label className={labelClass}>{"Vonzáskörzet"}</label>
                <div className="flex">
                  <input
                    type="number"
                    className={inputUnitClass}
                    {...register("area")}
                  />
                  <UnitBox unit="km" />
                </div>
                <div className="flex justify-end">
                  <button
                    type="submit"
                    className="border border-button-green bg-button-green text-white rounded-[20px] p-2 mt-6 mr-2 mb-6 bg-gradient-to-r from-[#C3D200] to-[#91A500]"
                  >
                    Szűrés
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </form>
      {/* className="ml-4" */}
      <div className="flex flex-col pt-6 pb-2 min-w-[480px] text-lgx font-medium text-smart-darkgray justify-center items-center">
        <div className="w-[440px] mb-3">
          <div className="text-base text-left mb-2">
            A jelen lista nem teljeskörű, véletlenszerűen kiválasztott
            beszállítókat tartalmaz.
          </div>
          {resultData &&
            resultData.map((item, index) => (
              <div key={index} className=" border-b-[1px] m-4">
                <ContractorCard key={index} model={item} />
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default ContractorsForm;
