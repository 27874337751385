import * as Yup from "yup";

const requiredText = "A mezőt kötelező kitölteni";
const requiredGpsText = "A GPS koordináta kötelező";
const requiredBoolText = "Lehalább az egyiket köteelező kiválasztani";
const wrongValueText = "Az érték nem megfelelő";
const wrongGpsValueText = "A GPS értékhez használja az alábbi gombokat";

export const validationSchema = () =>
  Yup.object({
    longitude: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .min(-180, wrongGpsValueText)
      .max(180, wrongGpsValueText)
      .notOneOf([0], wrongGpsValueText)
      .required(requiredGpsText),
    latitude: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .notOneOf([0], wrongGpsValueText)
      .min(-90, wrongGpsValueText)
      .max(90, wrongGpsValueText)
      .required(requiredGpsText),
    area: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .min(10, wrongValueText)
      .max(1000, wrongValueText)
      .required(requiredText),
    isGas: Yup.boolean().required(requiredBoolText),
    // .when(
    //   ["isSolarPanel", "isSolarCollector", "isHeatPump", "isWindTurbine"],
    //   {
    //     is: (value: boolean) => value === false,
    //     then: (schema) => schema.required(requiredBoolText),
    //     otherwise: (schema) => schema.required(),
    //   }
    // ),
    isSolarPanel: Yup.boolean().required(requiredBoolText),
    // .when(
    //   ["isGas", "isSolarCollector", "isHeatPump", "isWindTurbine"],
    //   {
    //     is: (value: boolean) => value === false,
    //     then: (schema) => schema.required(requiredBoolText),
    //     otherwise: (schema) => schema.required(),
    //   }
    // ),
    isSolarCollector: Yup.boolean().required(requiredBoolText),
    // .when(
    //   ["isGas", "isSolarPanel", "isHeatPump", "isWindTurbine"],
    //   {
    //     is: (value: boolean) => value === false,
    //     then: (schema) => schema.required(requiredBoolText),
    //     otherwise: (schema) => schema.required(),
    //   }
    // ),
    isHeatPump: Yup.boolean().required(requiredBoolText),
    // .when(
    //   ["isGas", "isSolarPanel", "isSolarCollector", "isWindTurbine"],
    //   {
    //     is: (value: boolean) => value === false,
    //     then: (schema) => schema.required(requiredBoolText),
    //     otherwise: (schema) => schema.required(),
    //   }
    // ),
    isWindTurbine: Yup.boolean().required(requiredBoolText),
    // .when(
    //   ["isGas", "isSolarPanel", "isSolarCollector", "isHeatPump"],
    //   {
    //     is: (value: boolean) => value === false,
    //     then: (schema) => schema.required(requiredBoolText),
    //     otherwise: (schema) => schema.required(),
    //   }
    // ),
  });
