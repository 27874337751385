import SolutionCard from "../../../common/components/solution-card";
import geothermicImage from "../../../assets/home/solutions/geothermic.jpeg";
import gridImage from "../../../assets/home/solutions/grid.jpeg";
import heatPumpImage from "../../../assets/home/solutions/heat-pump.jpeg";
import solarPanelImage from "../../../assets/home/solutions/solar-panel.jpeg";
import windTurbineImage from "../../../assets/home/solutions/wind-turbine.jpeg";
import PageSection from "../../../common/components/page-section";
import PageSectionMargin from "../../../common/components/page-section/page-section-margin";
import PageSectionContent from "../../../common/components/page-section/page-section-content";
import routePaths from "../../../common/constants/route-paths";

const Solutions = () => {
  return (
    <PageSection id="solutions">
      <PageSectionMargin additionalClassName="bg-[#efefe3] relative">
        <div className="absolute bottom-0 left-0 h-[252px] w-full bg-black"></div>
      </PageSectionMargin>
      <PageSectionContent additionalClassName="h-[564px] bg-[#efefe3] relative">
        <div className="absolute bottom-0 left-0 h-[252px] w-full bg-black"></div>
        <div className="absolute top-[80px] h-[48px] w-full text-5xl text-black font-bold text-center">
          Megoldások
        </div>
        <div className="absolute top-[152px] grid grid-cols-5 gap-3">
          <SolutionCard
            title="Geotermikus"
            image={geothermicImage}
            url={routePaths.GEOTHERMIC}
            portrait={true}
          ></SolutionCard>
          <SolutionCard
            title="Hálózat"
            image={gridImage}
            url={routePaths.GRID}
            portrait={true}
          ></SolutionCard>
          <SolutionCard
            title="Hőszivattyú"
            image={heatPumpImage}
            url={routePaths.HEATPUMP}
            portrait={true}
          ></SolutionCard>
          <SolutionCard
            title="Napelem"
            image={solarPanelImage}
            url={routePaths.SOLAR}
            portrait={true}
          ></SolutionCard>
          <SolutionCard
            title="Szélturbina"
            image={windTurbineImage}
            url={routePaths.WINDTURBINE}
            portrait={true}
          ></SolutionCard>
        </div>
      </PageSectionContent>
      <PageSectionMargin additionalClassName="bg-[#efefe3] relative">
        <div className="absolute bottom-0 left-0 h-[252px] w-full bg-black"></div>
      </PageSectionMargin>
    </PageSection>
  );
};

export default Solutions;
