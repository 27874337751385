import React, { useContext, useEffect, useState } from "react";
import OfferResult from "./offer-result";
import { OfferRequestModel } from "./offer-request/models";
import { post } from "../../common/api/api.service";
import OfferRequest from "./offer-request";
import {
  OfferDataActionType,
  OfferDataContext,
} from "../../common/providers/offer-data-context";
import Progress from "./progress";
import useTimer from "./use-timer";
import { EnergySourceMixModel } from "../../common/types/energySourceMixModel";
import {
  HeaderPathActionType,
  HeaderPathContext,
} from "../../common/providers/header-path-context";
import routePaths from "../../common/constants/route-paths";
import offerImg from "../../assets/jpg/offer.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import backIcon from "../../assets/solutions/back-icon-dark.svg";

const Offer = () => {
  const { offerDataState, dispatch } = useContext(OfferDataContext);
  const { headerPathState, dispatch: headerPathDispatch } =
    useContext(HeaderPathContext);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("location resetOffer", location.state?.resetOffer);
    if (location.state?.resetOffer) {
      dispatch({
        type: OfferDataActionType.setFormData,
        params: {
          offerRequest: undefined,
          offerResult: undefined,
        },
      });
    }
  }, [location]);

  const getRequestData = (src: OfferRequestModel) => {
    if (src.isExtended) {
      console.log("isExtended");
      const hasEnergyPerformanceCertificateId =
        src.energeticClass !== "unknown";
      if (hasEnergyPerformanceCertificateId) {
        return {
          latitude: src.latitude,
          longitude: src.longitude,
          area: src.usefulArea,
          hasGas: src.hasGas,
          adultCount: src.adults,
          childCount: src.children,
          heatingTemperature: src.winterInsideTemparature,
          coolingTemperature: src.summerInsideTemperature,
          electricCarEnergyRequirement: src.electricCarEnergyRequirement,
          otherEnergyRequirement: src.otherEnergyRequirement,
          epcId: src.energeticClass,
          epcUnknownValues: undefined,
        };
      } else {
        return {
          latitude: src.latitude,
          longitude: src.longitude,
          area: src.usefulArea,
          hasGas: src.hasGas,
          adultCount: src.adults,
          childCount: src.children,
          heatingTemperature: src.winterInsideTemparature,
          coolingTemperature: src.summerInsideTemperature,
          electricCarEnergyRequirement: src.electricCarEnergyRequirement,
          otherEnergyRequirement: src.otherEnergyRequirement,
          epcId: src.energeticClass,
          epcUnknownValues: {
            wallId: src.wallType,
            wallArea: src.wallArea,
            windowId: src.windowType,
            doorId: src.doorType,
            roofId: src.roofType,
            cellarId: src.cellarType,
            heatExchangerId: src.heatExchangerType,
            shadingId: src.shadingType,
            windowAreaSouth: src.windowAreaSouth,
            windowAreaNorth: src.windowAreaNorth,
            doorAreaSouth: src.doorAreaSouth,
            doorAreaNorth: src.doorAreaNorth,
          },
        };
      }
    } else {
      console.log("not isExtended");
      return {
        latitude: src.latitude,
        longitude: src.longitude,
        hasGas: src.hasGas,
      };
    }
  };

  const handleFormSubmit = async (formData: OfferRequestModel) => {
    const result = await post<EnergySourceMixModel>(
      formData.isExtended ? "calculate-extended" : "calculate-simple",
      getRequestData(formData),
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "post",
        },
      }
    );
    dispatch({
      type: OfferDataActionType.setFormData,
      params: { offerRequest: formData, offerResult: result },
    });
  };

  const { offerRequest, offerResult } = offerDataState;

  const { count, startTimer, endTimer } = useTimer();

  const [showResult, setShowResult] = useState(false);
  const [showProgress, setShowProgress] = useState(false);

  useEffect(() => {
    if (showProgress) {
      startTimer();
    }
  }, [startTimer]);

  useEffect(() => {
    if (count === 6) {
      endTimer();
      setShowProgress(false);
    }
  }, [endTimer, count]);

  useEffect(() => {
    if (
      showResult &&
      offerDataState.offerRequest &&
      offerDataState.offerResult
    ) {
      window.scrollTo({
        behavior: "auto",
        top: 0,
      });
      setShowProgress(true);
      startTimer();
      headerPathDispatch({
        type: HeaderPathActionType.setPath,
        params: {
          path: routePaths.OFFER,
        },
      });
    }
  }, [offerDataState]);

  useEffect(() => {
    if (!showProgress) {
      setShowResult(true);
    }
  }, [showProgress]);

  return (
    <>
      <div className="flex-1 h-full">
        <div className="flex justify-center relative">
          <div className="h-[560px] absolute">
            <img
              className="w-full min-h-[380px] max-h-[600px]"
              src={offerImg}
            />
          </div>
          <div className="flex w-full">
            <div className="grow w-0"></div>
            <div className="shrink w-[1024px] min-w-[640px] z-10 pt-[80px] pb-[80px]">
              <div className="border-[1px] border-smart-lightgray bg-smart-gray-white rounded-[4px]">
                {showProgress ? (
                  <div className="w-full">
                    <div className="flex pt-6 pb-2 min-w-[480px] z-10 justify-center items-center">
                      <Progress item={count}></Progress>
                    </div>
                  </div>
                ) : showResult && offerRequest && offerResult ? (
                  <div className="">
                    <div className="w-full flex items-center justify-center">
                      <div className="flex w-[480px] mt-[30px] items-center justify-start">
                        <div
                          className="flex w-8 h-8 border-smart-lightgray items-center cursor-pointer"
                          onClick={() => {
                            dispatch({
                              type: OfferDataActionType.setFormData,
                              params: {
                                offerRequest: offerDataState.offerRequest,
                                offerResult: undefined,
                              },
                            });
                            navigate(routePaths.OFFER);
                          }}
                        >
                          <img
                            className="w-6 h-6 border-smart-lightgray"
                            src={backIcon}
                            title="Vissza"
                          />
                        </div>
                        <p className="">Vissza az űrlapra</p>
                      </div>
                    </div>
                    <div className="flex pt-6 pb-2 min-w-[480px] z-10 text-lgxl font-bold text-smart-darkgray text-justify justify-center items-center">
                      <div className="w-[480px]">Ajánlás / eredmény</div>
                    </div>
                    <OfferResult
                      requestData={offerRequest}
                      resultData={offerResult}
                    />
                  </div>
                ) : (
                  <div className="w-full justify-center items-center">
                    <div className="flex justify-center items-center">
                      <div className="w-[440px] p-4 text-lgxl font-bold text-smart-darkgray text-justify">
                        Ajánlás / űrlap
                      </div>
                    </div>
                    <div className="flex justify-center items-center">
                      <div className="min-w-[480px]">
                        <OfferRequest handleFormSubmit={handleFormSubmit} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="grow w-0"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Offer;
