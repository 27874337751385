import { OfferRequestModel } from "./models";

export const initialValues: OfferRequestModel = {
  longitude: 0,
  latitude: 0,
  usefulArea: 0,
  energeticClass: "none",
  adults: 0,
  children: 0,
  winterInsideTemparature: 0,
  summerInsideTemperature: 0,
  hasGas: false,
  isExtended: false,
  wallType: "",
  wallArea: 0,
  windowType: "",
  doorType: "",
  roofType: "",
  cellarType: "",
  heatExchangerType: "",
  shadingType: "",
  windowAreaSouth: 0,
  windowAreaNorth: 0,
  doorAreaSouth: 0,
  doorAreaNorth: 0,
  electricCarEnergyRequirement: 0,
  otherEnergyRequirement: 0,
};
