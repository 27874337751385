import React, { useState } from "react";
import PageSection from "../../../common/components/page-section";
import PageSectionContent from "../../../common/components/page-section/page-section-content";
import PageSectionMargin from "../../../common/components/page-section/page-section-margin";
import SVG from "react-inlinesvg";
import foldSvg from "../../../assets/svg/fold-icon-light.svg";
import unfoldSvg from "../../../assets/svg/unfold-icon-light.svg";
import img1 from "../../../assets/home/terms-of-use/terms-of-use-1.jpg";
import img2 from "../../../assets/home/terms-of-use/terms-of-use-2.jpg";

const TermsOfUse = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <PageSection
      id="terms-of-use"
      additionalClassName="bg-clip-border bg-origin-border bg-center bg-no-repeat bg-cover"
      style={
        isOpen
          ? { backgroundImage: "url(" + img2 + ")" }
          : { backgroundImage: "url(" + img1 + ")" }
      }
    >
      <PageSectionMargin additionalClassName=""></PageSectionMargin>
      <PageSectionContent>
        <div className="w-full text-5xl grid grid grid-cols-1 text-white font-bold justify-center items-center">
          <div className="bg-smart-midgray p-6">
            <div className="flex w-full text-5xl font-bold mt-[10px] justify-between items-center">
              <p className="whitespace-nowrap">Felhasználási feltételek</p>
              <div
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
                className="flex w-[48px] h-[48px] rounded-full bg-smart-lightgreen items-center justify-center"
              >
                <img
                  className="w-[24px] h-[24px] bg-smart-lightgreen"
                  src={isOpen ? foldSvg : unfoldSvg}
                  title="Részletek"
                ></img>
              </div>
            </div>
            {!isOpen && (
              <div>
                <p className="text-lg font-[500] text-justify mt-8">
                  A weboldal használatával Ön elfogadja és beleegyezik a
                  következő felhasználási feltételek betartásába. Kérjük,
                  figyelmesen olvassa el az alábbiakat, mielőtt folytatná a
                  weboldal használatát…
                </p>
              </div>
            )}
            {isOpen && (
              <div>
                <p className="text-lg font-[500] text-justify mt-8">
                  A weboldal használatával Ön elfogadja és beleegyezik a
                  következő felhasználási feltételek betartásába. Kérjük,
                  figyelmesen olvassa el az alábbiakat, mielőtt folytatná a
                  weboldal használatát.
                </p>
                <p className="text-lg font-[500] text-justify mt-12">
                  Elfogadás A weboldal használatával Ön kijelenti, hogy
                  elolvasta, megértette és elfogadta az alábbi felhasználási
                  feltételeket. Ha nem ért egyet ezekkel a feltételekkel,
                  kérjük, hagyja el a weboldalt.
                </p>
                <p className="text-lg font-[500] text-justify mt-12">
                  Saját Felelősség A weboldal tartalma kizárólag tájékoztatási
                  célokat szolgál. Minden információ és tartalom a felhasználó
                  saját felelősségére történő felhasználásra szolgál. A weboldal
                  üzemeltetői semmilyen felelősséget nem vállalnak a tartalom
                  helytelenségéből vagy a felhasználásból eredő
                  következményekért.
                </p>
                <p className="text-lg font-[500] text-justify mt-12">
                  Tartalomváltoztatás Jog Az üzemeltetők fenntartják a jogot,
                  hogy bármikor módosítsák vagy frissítsék a weboldal tartalmát
                  anélkül, hogy előzetes értesítést adnának.
                </p>
                <p className="text-lg font-[500] text-justify mt-12">
                  Harmadik Féltől Származó Tartalmak A weboldal olyan harmadik
                  féltől származó tartalmakat is közölhet, amelyekért az
                  üzemeltetők nem vállalnak felelősséget. Ezek a harmadik fél
                  oldalak saját felhasználási feltételekkel és adatvédelmi
                  irányelvekkel rendelkezhetnek.
                </p>
                <p className="text-lg font-[500] text-justify mt-12">
                  Adatvédelem Az adatvédelmi irányelvek a weboldal külön
                  adatvédelmi szakaszában találhatók. Azok elolvasását és
                  megértését kérjük, mielőtt személyes adatokat megosztana
                  velünk.
                </p>
                <p className="text-lg font-[500] text-justify mt-12">
                  Jogvédelem Az üzemeltetők fenntartják a jogot, hogy jogi
                  eljárást kezdeményezzenek a felhasználási feltételek
                  megsértése esetén. Köszönjük, hogy használja az EnergoSmart
                  honlapot. Ha bármilyen kérdése vagy észrevétele van a
                  felhasználási feltételekkel kapcsolatban, kérjük, vegye fel
                  velünk a kapcsolatot.
                </p>
              </div>
            )}
          </div>
        </div>
      </PageSectionContent>
      <PageSectionMargin additionalClassName=""></PageSectionMargin>
    </PageSection>
  );
};

export default TermsOfUse;
