const routePaths = {
  INIT: "/",
  HOME: "/home",
  HOME_PROJECTINFO: "/home#project-info",
  HOME_TERMSOFUSE: "/home#terms-of-use",
  HOME_ABOUTUS: "/home#about-us",
  HOME_SOLUTIONS: "/home#solutions",
  HOME_ENERGETICDEFS: "/home#energetic-defs",
  OFFER: "/offer",
  GEOTHERMIC: "/geothermic",
  GRID: "/grid",
  HEATPUMP: "/heatPump",
  SOLAR: "/solarpanel",
  COLLECTOR: "/solarCollector",
  WINDTURBINE: "/windTurbine",
  CONTRACTOR: "/contractor",
  MAP: "/map",
  MATE: "https://uni-mate.hu/",
  IM: "https://kormany.hu/energiaugyi-miniszterium",
};

export default routePaths;
