import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import routePaths from "../../../common/constants/route-paths";

const HowToUse = () => {
  const navigate = useNavigate();

  const getPoints = () => {
    let content = [];
    for (let i = 0; i <= 5; i++) {
      content.push(
        <div
          key={i}
          className="w-[12px] h-[12px] m-2 border-[1px] rounded-full bg-smart-lightgray"
        ></div>
      );
    }
    return content;
  };

  const getImage1 = () => {
    return (
      <div className="w-[200px] h-[200px] bg-smart-green border-[4px] border-white rounded-b-[100px] rounded-r-[100px] text-smart-gray text-3xl font-[500] p-4 bg-gradient-to-r from-[#C3D200] to-[#91A500]">
        01
        <div className="flex justify-center">
          <div className="flex w-[104px] h-[104px] rounded-full bg-smart-lightgreen items-center justify-center">
            <div className="flex justify-center">
              <div className="w-[40px] h-[56px] border-[2px] border-white rounded-[4px]">
                <div className="w-[15px] h-[2px] border-[2px] border-white rounded-[4px] mt-1 ml-1"></div>
                <div className="w-[30px] h-[8px] border-[3px] border-white rounded-full mt-1 ml-1"></div>
                <div className="w-[30px] h-[8px] border-[3px] border-white rounded-full mt-1 ml-1"></div>
                <div className="flex items-center justify-end m-1">
                  <div className="w-[15px] h-[8px] border-[1px] bg-white rounded-full mt-1 ml-1"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getImage2 = () => {
    return (
      <div className="w-[200px] h-[200px] bg-smart-green border-[4px] border-white rounded-b-[100px] rounded-r-[100px] text-smart-gray text-3xl font-[500] p-4 bg-gradient-to-r from-[#C3D200] to-[#91A500]">
        02
        <div className="flex justify-center">
          <div className="flex w-[104px] h-[104px] rounded-full bg-smart-lightgreen items-center justify-center relative">
            <div className="flex justify-center">
              <div className="w-[40px] h-[56px] border-[2px] border-white rounded-[4px]">
                <div className="w-[15px] h-[2px] border-[2px] border-white rounded-[4px] mt-1 ml-1"></div>
                <div className="w-[30px] h-[8px] border-[3px] border-white rounded-full mt-1 ml-1"></div>
                <div className="w-[30px] h-[8px] border-[3px] border-white rounded-full mt-1 ml-1"></div>
                <div className="flex items-center justify-end m-1">
                  <div className="w-[15px] h-[8px] border-[1px] bg-white rounded-full mt-1 ml-1"></div>
                </div>
                <div className="top-[65px] left-[50px] absolute">
                  <div className="flex w-[30px] h-[30px] border-[3px] border-white border-[3px] rounded-full mt-1 ml-1 items-center justify-center text-white text-bold">
                    +
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getImage3 = () => {
    return (
      <div className="w-[200px] h-[200px] bg-smart-green border-[4px] border-white rounded-b-[100px] rounded-r-[100px] text-smart-gray text-3xl font-[500] p-4 bg-gradient-to-r from-[#C3D200] to-[#91A500]">
        03
        <div className="flex justify-center">
          <div className="flex w-[104px] h-[104px] rounded-full bg-smart-lightgreen items-center justify-center">
            <div className="flex justify-center">
              <div className="w-[40px] h-[56px] border-[2px] border-white rounded-[4px]">
                <div className="w-[15px] h-[2px] border-[2px] border-white rounded-[4px] mt-1 ml-1"></div>
                <div className="flex items-center justify-center  relative">
                  <div className="w-[20px] h-[20px] border-[2px] rounded-full bg-white border-white mt-1"></div>
                  <div className="absolute w-[12px] h-[12px] bg-smart-green top-[2px] left-[6px]"></div>
                  <div className="absolute w-[12px] h-[12px] bg-smart-lightgreen top-[2px] left-[6px] z-10"></div>
                </div>
                <div className="items-center justify-center p-1">
                  <div className="w-[26px] h-[2px] border-[2px] border-white bg-white mb-[2px]"></div>
                  <div className="w-[26px] h-[2px] border-[2px] border-white bg-white mb-[2px]"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="bg-smart-sand pt-[40px]">
        <div>
          <div className="flex justify-center mb-[40px] text-lgxl font-bold text-justify">
            Hogyan használjam?
          </div>
          <div className="flex justify-center mt-4 grid grid-flow-row lg:grid-flow-col">
            <div className="flex grid grid-flow-row lg:grid-flow-col">
              <div className="">
                <div className="w-[208px] h-[208px]">{getImage1()}</div>
                <div className="w-[208px] h-[140px] mt-8 text-lg font-[500] text-center">
                  Kattintson a menüben az “Ajánlás”vagy itt lent a “Kezdjük”
                  gombra és töltse ki a 4 egyszerű kérdésből álló űrlapot
                </div>
              </div>
              <div className="flex lg:mt-[80px] md:mt-[20px] md:mb-[20px]">
                {getPoints()}
              </div>
            </div>
            <div className="flex grid grid-flow-row lg:grid-flow-col">
              <div className="">
                <div className="w-[208px] h-[208px]">{getImage2()}</div>
                <div className="w-[208px] h-[140px] mt-8 text-lg font-[500] text-center">
                  Amennyiben pontosabb eredményt szeretne, a “További kérdések”
                  gombra kattintva töltse ki a bővebb űrlapot
                </div>
              </div>
              <div className="flex lg:mt-[80px] md:mt-[20px] md:mb-[20px]">
                {getPoints()}
              </div>
            </div>
            <div className="flex grid grid-flow-row lg:grid-flow-col">
              <div className="">
                <div className="w-[208px] h-[208px]">{getImage3()}</div>
                <div className="w-[208px] h-[140px] mt-8 text-lg font-[500] text-center">
                  Az “Ajánlás kérése” gombra katintva az eredmény oldalon nézze
                  meg a megadott adatok alapján készült energia mixet
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center mb-[80px] mt-[40px]">
          <div className="flex w-[280px] h-[80px] text-center align-middle">
            <button
              className="w-[100%] h-[100%] text-lgxl font-bold text-white border-2 bg-smart-green rounded-[40px] bg-gradient-to-r from-[#C3D200] to-[#91A500]"
              onClick={() => {
                navigate(routePaths.OFFER);
              }}
            >
              Kezdjük
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowToUse;
