import { detailStyles } from "./detail-styles";

export const solutionsOfThermalOrFuelContent = {
    title: "Energiatermelési megoldások - hő, üzemanyag",
    body: (
        <>
            <p>
                <span className={detailStyles.highlightStrong}>Kandalló, kályha</span>  eredetileg fával, később más fosszilis anyagok(szén, gáz, olaj) égetésével
                hőt/hőenergiát termelő többnyire fűtésre használt berendezés. ma már vannak elektromos kandallók is, melyek villamos energiát használnak fűtésre.
            </p>

            <p>
                <span className={detailStyles.highlightStrong}>Kazán</span> többnyire foxilis energiaforrás (vagy energiaforrások)  égetésével nyert hővel zárt
                rendszerben más közeget, általában vizet melegítenek fel, melyet később csöveken átáramoltatva fűtésre használnak.  (A gőzkazánok gőzt állítanak
                elő, melyet akár mechanikai munkavégzésre is fel lehet használni.)
            </p>

            <p>
                <span className={detailStyles.highlightStrong}>Kondenzációs kazán</span> olyan jó hatásfokú kazán, melyben az energiaforrás elégetésekor
                keletkező égéstermékekből a vízgőz kondenzálásával kinyerhető hőt is felhasználják a víz melegítésére.
            </p>

            <p>
                <span className={detailStyles.highlightStrong}>Napkollektor</span> fűtésre, vízmelegítősre használható hőenergiát állít elő a napenergiából.
            </p>

            <p>
                <span className={detailStyles.highlightStrong}>Geotermikus energia kinyerés</span> a Föld belső hőjét hasznosító berendezés, mely hő, vagy
                villamos energiát biztosít.
            </p>

            <p>
                <span className={detailStyles.highlightStrong}>Hőszivattyú</span> a háztartási hűtőgépek működéséhez hasonló eszköz, mely az egyik helyről
                (a földből, vízből, külső levegőből) hőt visz át egy másik helyre (pl. a belső terek fűtésére). Fontos: a hőszivattyúk egységnyi (villamos)
                energia felhasználásával annál 3-5-ször több hőenergiát képest átvinne egyik helyről a másikra, vagyis egység energiát felhasználva a 3-5
                egységnyi energiával „tud” fűteni.
            </p>

            <p>
                <span className={detailStyles.highlightStrong}>Biogáz</span> szerves (szilárd, folyékony, növényi, állati eredetű, közismerten pl. repce)
                anyagokból, az un biomasszából (mikróbák segítségével) előállított gáz, mely 45-75%-ban tartalmazhat metánt (széndioxid, nitrogén és
                vízgőz mellett). Tisztítás után a biogáz elérheti a földgáz minőségét.
            </p>

            <p>
                <span className={detailStyles.highlightStrong}>Bioüzemanyag</span> bio tüzelőanyag, a növényi, vagy állati eredetű biomasszából előállított
                szilárd, cseppfolyós (bioetanol, biodizel, biokerozin), vagy gáznemű (biogáz) előállítása.
            </p>

            <p>
                <span className={detailStyles.highlightStrong}>Alga termelés</span> vízben (erre a célra épített környezetben fény hatására, széndioxidot
                felhasználva intenzíven) növekvő algák használata biomassza/biogáz készítésére. Fontos: a mezőgazdasági növényekhez képest egységnyi területen
                jóval több energia termelhető így.
            </p>

            <p>
                <span className={detailStyles.highlightStrong}>Hulladék hasznosítás</span> a hulladékot is fel lehet dolgozni a biomasszához hasonlóan. Fontos,
                hogy akár veszélyes hulladékot is fel lehet dolgozni pl. plazma alkalmazásával.
            </p>
        </>
    )
};