import { useContext, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { OfferRequestModel } from "./models";
import { useNavigate } from "react-router-dom";
import routePaths from "../../../common/constants/route-paths";
import UnitBox from "../../../common/components/unit";
import { initialValues } from "./service";
import { style } from "./config";
import { labelValues } from "../../../common/constants/label-texts";
import mapSvg from "../../../assets/svg/map-icon-dark.svg";
import locationSvg from "../../../assets/svg/location-icon-dark.svg";
import { validationSchema } from "./validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { GlobalContext } from "../../../common/providers/global-provider";
import FormError from "../../../common/components/form-error";
import {
  OfferDataActionType,
  OfferDataContext,
} from "../../../common/providers/offer-data-context";
import {
  HeaderPathActionType,
  HeaderPathContext,
} from "../../../common/providers/header-path-context";

type Props = {
  handleFormSubmit: (data: OfferRequestModel) => void;
};

const OfferRequestForm = ({ handleFormSubmit }: Props) => {
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setShowCoordinates(true);
        setValue("latitude", position.coords.latitude);
        setValue("longitude", position.coords.longitude);
      });
    }
  };

  const { state, dispatch } = useContext(GlobalContext);
  const { offerDataState, dispatch: offerDispatch } =
    useContext(OfferDataContext);

  const { headerPathState, dispatch: headerPathDispatch } =
    useContext(HeaderPathContext);

  useEffect(() => {
    if (offerDataState?.offerRequest === undefined) {
      reset(initialValues);
      setIsExtended(false);
    } else {
      reset(offerDataState.offerRequest);
      setIsExtended(offerDataState.offerRequest.isExtended);
    }
    setIsEnergeticClassTypeSelected(
      offerDataState.offerRequest?.energeticClass !== "unknown"
    );
  }, [offerDataState]);

  const {
    checkBoxClass,
    divClass,
    inputClass,
    inputUnitClass,
    inputNearClass,
    inputUnitNearClass,
    inputUnitNearClass2,
    labelClass,
    locationClass,
    mapClass,
  } = style;

  console.log("offerDataState?.offerRequest", offerDataState?.offerRequest);
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm<OfferRequestModel>({
    // defaultValues:
    //   offerDataState?.offerRequest === null ||
    //   offerDataState?.offerRequest === undefined
    //     ? initialValues
    //     : offerDataState?.offerRequest,
    resolver: yupResolver(validationSchema()),
  });

  const navigate = useNavigate();

  const [isExtended, setIsExtended] = useState(false);
  const [isEnergeticClassTypeSelected, setIsEnergeticClassTypeSelected] =
    useState(false);
  const [showCoordinates, setShowCoordinates] = useState(false);

  const onSubmit: SubmitHandler<OfferRequestModel> = async (formData) => {
    handleFormSubmit(formData);
  };

  useEffect(() => {
    setValue("isExtended", isExtended);
  }, [isExtended]);

  return (
    <form
      className="flex flex-col graw-4 mt-6"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="m-4">
        <div className="relative">
          <label className={labelClass}>{labelValues.latitude}</label>
          <input className={inputClass} {...register("latitude")} />
          <FormError errors={errors} errorKey={"latitude"} />
        </div>
        <div className="relative">
          <label className={labelClass}>{labelValues.longitude}</label>
          <input className={inputClass} {...register("longitude")} />
          <FormError errors={errors} errorKey={"longitude"} />
        </div>
        <div className="flex mb-4 mt-2 justify-center">
          <div role="button" className={locationClass} onClick={getLocation}>
            <img className="mr-2" src={locationSvg} title="Aktuális pozíció" />
            Helyzetem
          </div>
          <div
            role="button"
            className={mapClass}
            onClick={() => {
              offerDispatch({
                type: OfferDataActionType.setFormData,
                params: { offerRequest: getValues(), offerResult: undefined },
              });
              headerPathDispatch({
                type: HeaderPathActionType.setPath,
                params: {
                  path: routePaths.OFFER,
                },
              });
              navigate(routePaths.MAP, {
                state: {
                  returnUrl: routePaths.OFFER,
                  lat: getValues().latitude,
                  lng: getValues().longitude,
                },
              });
            }}
          >
            <img className="mr-2" src={mapSvg} title="Térkép" />
            Térkép
          </div>
        </div>

        <div className="flex justify-between">
          <div className={divClass}>
            <input
              type="checkbox"
              className={checkBoxClass}
              {...register("hasGas")}
            />
            <label className={labelClass}>{labelValues.hasGas}</label>
          </div>
        </div>

        {isExtended && (
          <>
            <div className="relative">
              <label className={labelClass}>{labelValues.usefulArea}</label>
              <div className="flex">
                <input className={inputUnitClass} {...register("usefulArea")} />
                <UnitBox unit="m2" />
              </div>
              <FormError errors={errors} errorKey={"usefulArea"} />
            </div>

            <div className="flex gap-x-4">
              <div className="relative">
                <label className={labelClass}>{labelValues.adults}</label>
                <input className={inputNearClass} {...register("adults")} />
                <FormError errors={errors} errorKey={"adults"} />
              </div>
              <div className="relative">
                <label className={labelClass}>{labelValues.children}</label>
                <input className={inputNearClass} {...register("children")} />
                <FormError errors={errors} errorKey={"children"} />
              </div>
            </div>
            <div className="flex gap-x-4">
              <div className="flex-auto relative">
                <label className={labelClass}>
                  {labelValues.winterInsideTemparature}
                </label>
                <div className="flex">
                  <input
                    className={inputUnitNearClass}
                    {...register("winterInsideTemparature")}
                  />
                  <UnitBox unit="°C" />
                </div>
                <FormError
                  errors={errors}
                  errorKey={"winterInsideTemparature"}
                />
              </div>
              <div className="flex-auto relative">
                <label className={labelClass}>
                  {labelValues.summerInsideTemperature}
                </label>
                <div className="flex">
                  <input
                    className={inputUnitNearClass}
                    {...register("summerInsideTemperature")}
                  />
                  <UnitBox unit="°C" />
                </div>
                <FormError
                  errors={errors}
                  errorKey={"summerInsideTemperature"}
                />
              </div>
            </div>
            <div className="flex gap-x-4">
              <div className="flex-auto relative">
                <label className={labelClass}>
                  {labelValues.electricCarEnergyRequirement}
                </label>
                <div className="flex">
                  <input
                    className={inputUnitNearClass2}
                    {...register("electricCarEnergyRequirement")}
                  />
                  <UnitBox unit="kWh/év" />
                </div>
                <FormError
                  errors={errors}
                  errorKey={"electricCarEnergyRequirement"}
                />
              </div>
              <div>
                <label className={labelClass}>
                  {labelValues.otherEnergyRequirement}
                </label>
                <div className="flex">
                  <input
                    className={inputUnitNearClass2}
                    {...register("otherEnergyRequirement")}
                  />
                  <UnitBox unit="kWh/év" />
                </div>
                <FormError
                  errors={errors}
                  errorKey={"otherEnergyRequirement"}
                />
              </div>
            </div>
            <div className="relative">
              <label className={labelClass}>{labelValues.energeticClass}</label>
              <select
                className={inputClass}
                {...register("energeticClass")}
                onChange={(e) => {
                  setIsEnergeticClassTypeSelected(e.target.value !== "unknown");
                  // if (e.target.value !== "unknown") {
                  //   setValue("buildType", "none");
                  // }
                }}
              >
                {state.items.lookups.epcs.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
              {getValues().energeticClass === "none" && (
                <FormError errors={errors} errorKey={"energeticClass"} />
              )}
            </div>
            {!isEnergeticClassTypeSelected && (
              <>
                {/* <div className="relative">
                  <label className={labelClass}>{labelValues.buildType}</label>
                  <select className={inputClass} {...register("buildType")}>
                    {state.items.lookups.buildingCharacteristics.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  <FormError errors={errors} errorKey={"buildType"} />
                </div> */}
                <div className="relative">
                  <label className={labelClass}>{labelValues.wallType}</label>
                  <select className={inputClass} {...register("wallType")}>
                    {state.items.lookups.walls.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  <FormError errors={errors} errorKey={"wallType"} />
                </div>
                <div className="relative">
                  <label className={labelClass}>{labelValues.wallArea}</label>
                  <div className="flex">
                    <input
                      className={inputUnitClass}
                      {...register("wallArea")}
                    />
                    <UnitBox unit="m2" />
                  </div>
                  <FormError errors={errors} errorKey={"wallArea"} />
                </div>
                <div className="relative">
                  <label className={labelClass}>{labelValues.windowType}</label>
                  <select className={inputClass} {...register("windowType")}>
                    {state.items.lookups.windows.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  <FormError errors={errors} errorKey={"windowType"} />
                </div>
                <div className="relative">
                  <label className={labelClass}>
                    {labelValues.windowAreaSouth}
                  </label>
                  <div className="flex">
                    <input
                      className={inputUnitClass}
                      {...register("windowAreaSouth")}
                    />
                    <UnitBox unit="m2" />
                  </div>
                  <FormError errors={errors} errorKey={"windowAreaSouth"} />
                </div>
                <div className="relative">
                  <label className={labelClass}>
                    {labelValues.windowAreaNorth}
                  </label>
                  <div className="flex">
                    <input
                      className={inputUnitClass}
                      {...register("windowAreaNorth")}
                    />
                    <UnitBox unit="m2" />
                  </div>
                  <FormError errors={errors} errorKey={"windowAreaNorth"} />
                </div>
                <div className="relative">
                  <label className={labelClass}>{labelValues.doorType}</label>
                  <select className={inputClass} {...register("doorType")}>
                    {state.items.lookups.doors.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  <FormError errors={errors} errorKey={"doorType"} />
                </div>
                <div className="relative">
                  <label className={labelClass}>
                    {labelValues.doorAreaSouth}
                  </label>
                  <div className="flex">
                    <input
                      className={inputUnitClass}
                      {...register("doorAreaSouth")}
                    />
                    <UnitBox unit="m2" />
                  </div>
                  <FormError errors={errors} errorKey={"doorAreaSouth"} />
                </div>
                <div className="relative">
                  <label className={labelClass}>
                    {labelValues.doorAreaNorth}
                  </label>
                  <div className="flex">
                    <input
                      className={inputUnitClass}
                      {...register("doorAreaNorth")}
                    />
                    <UnitBox unit="m2" />
                  </div>
                  <FormError errors={errors} errorKey={"doorAreaNorth"} />
                </div>
                <div className="relative">
                  <label className={labelClass}>{labelValues.roofType}</label>
                  <select className={inputClass} {...register("roofType")}>
                    {state.items.lookups.roofs.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  <FormError errors={errors} errorKey={"roofType"} />
                </div>
                <div className="relative">
                  <label className={labelClass}>{labelValues.cellarType}</label>
                  <select className={inputClass} {...register("cellarType")}>
                    {state.items.lookups.cellars.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  <FormError errors={errors} errorKey={"cellarType"} />
                </div>
                <div className="relative">
                  <label className={labelClass}>
                    {labelValues.heatExchangerType}
                  </label>
                  <select
                    className={inputClass}
                    {...register("heatExchangerType")}
                  >
                    {state.items.lookups.heatExchangers.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  <FormError errors={errors} errorKey={"heatExchangerType"} />
                </div>
                <div className="relative">
                  <label className={labelClass}>
                    {labelValues.shadingType}
                  </label>
                  <select className={inputClass} {...register("shadingType")}>
                    {state.items.lookups.shadings.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  <FormError errors={errors} errorKey={"shadingType"} />
                </div>
              </>
            )}
          </>
        )}
      </div>
      <div className="flex m-4 justify-between">
        <button
          type="button"
          className={
            "w-[176px] border-[1px] border-black bg-button-smart-white text-lg text-smart-black rounded-[20px] p-2 mr-2"
          }
          onClick={() => {
            setIsExtended(!isExtended);
          }}
        >
          {isExtended ? "Kevesebb kérdés" : "További kérdések"}
        </button>
        <button
          className="w-[154px] border border-button-green bg-button-green text-smart-white w-[164px] rounded-[20px] p-2 mr-2 bg-gradient-to-r from-[#C3D200] to-[#91A500]"
          onClick={() => {
            return onSubmit;
          }}
        >
          Ajánlás kérése
        </button>
      </div>
      {/* <div className="text-base text-smart-darkgray mt-[18px]">
        {!isExtended
          ? "A “További kérdések” gomb megnyomásával további mezőket tölthet ki, amelyek mind pontosítják az ajánlást."
          : ""}
      </div> */}
    </form>
  );
};

export default OfferRequestForm;
