import { useState } from "react";
import { Dialog } from "@headlessui/react";

import Carousel from "./carousel";
import ProjectInformation from "./project-information";
import Solutions from "./solutions";
import AboutUs from "./about-us";
import EnergeticDefinitions from "./energetic-definitions";
import TermsOfUse from "./terms-of-use";
import HowToUse from "./how-to-use";

const Home = () =>
{
    return (
        <>
            <Carousel timeOut={8000}></Carousel>
            <ProjectInformation></ProjectInformation>
            <Solutions></Solutions>
            <AboutUs></AboutUs>
            <EnergeticDefinitions></EnergeticDefinitions>
            <TermsOfUse></TermsOfUse>
            <HowToUse></HowToUse>
        </>
    );
};

export default Home;
