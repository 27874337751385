import { Route, Routes } from "react-router-dom";
import routePaths from "../../common/constants/route-paths";
import Layout from "../../common/components/layout";
import Contractor from "../contractor";
import Home from "../home";
import MapLoader from "../map";
import Offer from "../offer";
import Geothermic from "../solutions/solution-details/geothermic";
import Grid from "../solutions/solution-details/grid";
import Heatpump from "../solutions/solution-details/heatpump";
import Solar from "../solutions/solution-details/solar";
import Windturbine from "../solutions/solution-details/windturbine";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path={routePaths.INIT}
        element={
          <Layout>
            <Home />
          </Layout>
        }
      />
      <Route
        path={routePaths.HOME}
        element={
          <Layout>
            <Home />
          </Layout>
        }
      />
      <Route
        path={routePaths.OFFER}
        element={
          <Layout>
            <Offer />
          </Layout>
        }
      />
      <Route
        path={routePaths.CONTRACTOR}
        element={
          <Layout>
            <Contractor />
          </Layout>
        }
      />
      <Route
        path={routePaths.GEOTHERMIC}
        element={
          <Layout>
            <Geothermic />
          </Layout>
        }
      />
      <Route
        path={routePaths.GRID}
        element={
          <Layout>
            <Grid />
          </Layout>
        }
      />
      <Route
        path={routePaths.HEATPUMP}
        element={
          <Layout>
            <Heatpump />
          </Layout>
        }
      />
      <Route
        path={routePaths.SOLAR}
        element={
          <Layout>
            <Solar />
          </Layout>
        }
      />
      <Route
        path={routePaths.GEOTHERMIC}
        element={
          <Layout>
            <Geothermic />
          </Layout>
        }
      />
      <Route
        path={routePaths.COLLECTOR}
        element={
          <Layout>
            <Solar />
          </Layout>
        }
      />
      <Route
        path={routePaths.WINDTURBINE}
        element={
          <Layout>
            <Windturbine />
          </Layout>
        }
      />
      <Route
        path={routePaths.MAP}
        element={
          <Layout hideHeader={true} hideFooter={true}>
            <MapLoader />
          </Layout>
        }
      />
    </Routes>
  );
};
