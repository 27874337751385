import React from "react";

type Props = { unit: string };

const UnitBox = ({ unit }: Props) => {
  return (
    <div
      className={`flex justify-center items-center h-14 px-3 whitespace-nowrap bg-gray-color border border-b-smart-extralightgray rounded-r-[20px]`}
    >
      {unit}
    </div>
  );
};

export default UnitBox;
