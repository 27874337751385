export const labelValues = {
  projectName: "EnergoSmart",
  //   address: "Helyszín",
  latitude: "Földrajzi szélesség",
  longitude: "Földrajzi hosszúság",
  ownership: "Tulajdonosi viszony",
  usefulArea: "Hasznos terület",
  energeticClass: "Energetikai besorolás",
  buildType: "Építési sajátosság",
  adults: "Felnőtt lakosok száma",
  children: "Gyerek lakosok száma",
  winterInsideTemparature: "Fűtési szezonban beállított hőmérséklet",
  summerInsideTemperature: "Nyári légkondícionálás alsó hőmérséklete",
  hasGas: "van gáz bevezetve",
  hasNotGas: "nincs gáz bevezetve",
  wallType: "Külső fal szerkezete",
  wallArea: "Falak felülete",
  windowType: "Ablakok",
  windowsWallRelation: "Ablak felület a fal felületéhez képest",
  windowsOrientation: "Ablak felületek jellemző tájolása",
  roofType: "Tetőszerkezet",
  //   isRoofIsolated: "szigetelt padlástér van",
  //   isNotRoofIsolated: "szigetelt padlástér nincs",
  cellarType: "Pince",
  doorType: "Ajtók",
  heatingType: "Fűtési rendszer",
  heatExchangerType: "Hőcserélős rendszer",
  shadingType: "Árnyékoló rendszer",
  energyStoreType: "Energiatárolás",
  buildingOrientation: "Tájolás",
  peopleAtWorktime: "Munkaidőben is otthon tartózkodók száma",
  windowAreaSouth: "Ablakok felülete délen",
  windowAreaNorth: "Ablakok felülete északon",
  doorAreaSouth: "Ajtók felülete délen",
  doorAreaNorth: "Ajtók felülete északon",
  electricCarEnergyRequirement: "Elektromos autó töltés",
  otherEnergyRequirement: "Egyéb, pl. medence fűtés",
};
