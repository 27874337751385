import projectInfo1 from "../../../assets/home/project-info/project-info-1.jpg";
import projectInfo2 from "../../../assets/home/project-info/project-info-2.jpg";
import PageSection from "../../../common/components/page-section";
import PageSectionContent from "../../../common/components/page-section/page-section-content";
import PageSectionMargin from "../../../common/components/page-section/page-section-margin";

const ProjectInformation = () =>
{
    return (
        <PageSection id="project-info">
            <PageSectionMargin additionalClassName="">
            </PageSectionMargin>
            <PageSectionContent>
                <div className="flex grid grid-cols-1 justify-items-center gap-4 lg:grid-cols-2 lg:justify-items-start">
                    <div className="relative pb-[30px] h-[450px]">
                        <div className="h-[400px]">
                            <img src={projectInfo1} />
                        </div>
                        <div className="w-[272px] h-[272px] p-2 bg-white absolute -bottom-[35px] left-[160px] animate-[twist_5s_infinite]">
                            <img src={projectInfo2} />
                        </div>
                    </div>
                    <div className="mt-10 lg:mt-0">
                        <div className="w-full text-5xl text-black font-bold text-center">
                            Projekt információk
                        </div>
                        <p className="text-xl text-justify mt-12">
                            A MATE Körforgásos Gazdaság Elemző Központ ÉZFF/212/2022-TIM
                            azonosítószámú „Energiahatékonysági zöld Projekt” keretében „A
                            Zöldinnovációs és Energiahatékonysági Expo” és a „Zöld fesztivál /
                            Zöld Egyetemi Napok” fontos alprojektje az ENERGIAHASZNOSÍTÁSI
                            OKOSTÉRKÉP.
                        </p>
                        <p className="text-xl text-justify mt-4">
                            Az okos, multidiszciplináris, interaktív energia
                            felhasználási térkép támogatja a lokálisan rendelkezésre álló
                            (hálózati, megújuló és alternatív) energiaforrások helyi terep és
                            időjárási viszonyoktól és az épített környezettől függő alkalmazását
                            olyan optimalizált energiatermelési mix és teljes körű energia
                            menedzsment bevezetésével, mely zöldebb, biztonságosabban
                            rendelkezésre áll, és hatékonyabb.
                        </p>
                    </div>
                </div>
            </PageSectionContent>
            <PageSectionMargin additionalClassName="">
            </PageSectionMargin>
        </PageSection>
    );
};

export default ProjectInformation;
