import { detailStyles } from "./detail-styles";

export const possibleEnergySourcesContent = {
    title: "Lehetséges energiaforrások",
    body: (
        <>
            <p>
                A lakossági energiafelhasználás szempontjából a lehetséges energiaforrásokat 8 nagy csoportba lehet sorolni:
            </p>

            <p>
                <span className={detailStyles.highlight}>Természetes fosszilis energiaforrások</span>, mint a fa, szén, gáz, gázolaj stb.;
            </p>

            <p>
                <span className={detailStyles.highlight}>Hálózatos villamosenergia</span>, melyet akár fosszilis tüzelőanyag alkalmazásával, akár más energiaforrások, mint a nukleáris energia, napenergia,
                szélenergia vízenergia, stb. állítanak elő és hálózaton keresztül juttatják el felhasználókhoz;
            </p>

            <p>
                <span className={detailStyles.highlight}>Megújuló energiaforrások</span> olyan energiahordozók, energiaforrások, melyek folyamatosan megújulnak, vagy másképpen fogalmazva nem fogynak el
                (legalábbis „emberi léptékben”) - ezek a föld belső energiájából, vagy végső soron a napenergiától függenek, azaz ide tartoznak a napenergia,
                a geotermikus energia, a szélenergia, a hullámenergia, az ár-apály energia, vagy akár a biogáztermelés, vagy hidrogén előállítása megujuló
                energiaforrásból alkalmazott villamosenergiával is;
            </p>

            <p>
                <span className={detailStyles.highlight}>Fenntartható energiaforrás</span> az általánosan alkalmazott megfogalmazás szerint a fenntartható olyan megoldást jellemez, mely alkalmazása „kielégíti a
                jelen szükségleteit anélkül, hogy veszélyeztetné a jövő generációinak lehetőségét saját szükségleteik kielégítésére”, vagy kevésbé szakszerűen fogalmazva,
                olyan energiaforrás, mely végső soron nem szennyezi a környezetet - ez utóbbit is figyelembe véve, lényegében az összes megújuló energiaforrással
                előállított villamos energia, vagy az a bioüzemanyag, melynek elégetésekor keletkező széndioxid az előállításához alkalmazott növény a termesztése során
                megköt;
            </p>

            <p>
                <span className={detailStyles.highlight}>Alternatív energiaforrás</span>, mint a hidrogén, melyet előállíthatnak megújuló energia szolgáltatta villamos energiával, vagy fenntarthatónak nevezett
                eljárással biomasszából, és melyet a közvetlen égésekor, vagy pl. hidrogén üzemanyagcellákban is felhasználhatják;
            </p>

            <p>
                <span className={detailStyles.highlight}>Kapcsolt energiatermelés</span> co-generációs, vagy trigenerációs energiatermelés, mely során a belsőégésű motorra, vagy gázturbinás megoldásra alapozva
                egyszerre van hő és villamos energiatermelés, illetve akár a fűtés mellett a hűtés is megoldott;
            </p>

            <p>
                <span className={detailStyles.highlight}>Energia mix, aktív energiamenedzsment</span>, amikor egyszerre többféle energiaforrásból kapott energiafelhasználást minden lehetséges termelési, felhasználói
                egységeket az energia tárolására, visszanyerésére, a felhasználási igények csökkentésére alkalmas eszközökkel együtt a pillanatnyi igények alapján
                szabályoznak.
            </p>

            <p>
                <span className={detailStyles.highlight}>Lokálisan elérhető energiaforrás</span> az adott helyen/helyszínen elérhető energiaforrás.
            </p>
        </>
    )
};