import React, { useContext, useEffect, useState } from "react";
import { labelValues } from "../../../common/constants/label-texts";
import { OfferRequestModel } from "../offer-request/models";
import { OfferResultModel } from "./models";
import { GlobalContext } from "../../../common/providers/global-provider";
import SVG from "react-inlinesvg";
import settingsSvg from "../../../assets/svg/settings-icon-dark.svg";
import shippingSvg from "../../../assets/svg/shipping-icon-dark.svg";
import { useNavigate } from "react-router-dom";
import routePaths from "../../../common/constants/route-paths";
import {
  OfferDataActionType,
  OfferDataContext,
} from "../../../common/providers/offer-data-context";
import { EnergySourceMixModel } from "../../../common/types/energySourceMixModel";
import {
  HeaderPathActionType,
  HeaderPathContext,
} from "../../../common/providers/header-path-context";
import { Doughnut } from "react-chartjs-2";
import { EnergySourceMix } from "../../../common/types/energySourceMix";
import {
  ChartOptions,
  BackgroundColors,
  BorderColors,
  ChartData,
  ShadowPlugin,
} from "../../../common/types/chartDataType";
import foldSvg from "../../../assets/svg/fold-icon-lightgray.svg";
import unfoldSvg from "../../../assets/svg/unfold-icon-lightgray.svg";

const labelClass = "text-base text-smart-midgray";
const labelValueClass = "mb-2 text-base text-smart-darkgray";

type Props = {
  requestData: OfferRequestModel;
  resultData: EnergySourceMixModel;
};

function currencyFormat(num: number) {
  return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

function getChartData(energySourceMix: EnergySourceMix[]): ChartData {
  let result: ChartData = {
    labels: [],
    datasets: [
      {
        label: "",
        backgroundColor: [],
        borderColor: [],
        borderWidth: 4,
        data: [],
      },
    ],
  };

  if (energySourceMix && energySourceMix.length > 0) {
    energySourceMix.map((x, index) => {
      result.labels.push(`"${x.energySource.name}(${x.ratio}%)"`);
      result.datasets.length = 1;
      result.datasets[0].data.push(x.ratio);
      result.datasets[0].backgroundColor.push(BackgroundColors[index]);
      result.datasets[0].borderColor.push("#FFFFFF");
    });
  }

  return result;
}

const OfferResult = ({ requestData, resultData }: Props) => {
  const navigate = useNavigate();

  const { state } = useContext(GlobalContext);
  const { offerDataState, dispatch } = useContext(OfferDataContext);
  const { headerPathState, dispatch: headerPathDispatch } =
    useContext(HeaderPathContext);

  const [isOpen, setIsOpen] = useState(true);
  const [model, setModel] = useState<OfferResultModel>();
  const [chartData, setChartData] = useState<ChartData>();

  useEffect(() => {
    if (requestData && resultData) {
      setModel({ offerRequest: requestData, offer: resultData });
      setChartData(getChartData(resultData.energySourceMix));
    }
  }, [requestData, resultData]);
  console.log("resultData", resultData);

  const totalCost = resultData.specificCostHufPerKwH;
  const isEnergeticClassSelected = requestData.energeticClass !== "unknown";

  return model ? (
    <div className="">
      <div className="">
        <div className="flex flex-col items-center">
          <div className="pt-6 pb-4 w-[480px] z-10 text-lgx font-medium text-smart-darkgray text-justify">
            <div
              className={
                isOpen
                  ? "flex mb-3 border-[1px] border-smart-midgray rounded radius-[4px] items-center p-4"
                  : "flex mb-3 h-[80px] border-[1px] border-smart-midgray rounded radius-[4px] items-center p-4"
              }
            >
              <div className="w-[100%]">
                <div className="flex w-full text-5xl font-bold justify-center items-center">
                  <p className="text-lg text-smart-darkgray w-[100%]">
                    Űrlapon megadott adatok
                  </p>
                  <div className="flex w-full h-[48px] justify-end items-center">
                    {
                      <div
                        onClick={() => {
                          setIsOpen(!isOpen);
                        }}
                        className={
                          isOpen
                            ? "flex w-[48px] h-[48px] rounded-full bg-smart-midlelightgray font-normal justify-center items-center"
                            : "flex w-[48px] h-[48px] rounded-full bg-smart-midgray font-normal justify-center items-center"
                        }
                      >
                        <img
                          src={isOpen ? foldSvg : unfoldSvg}
                          title="Részletek"
                        ></img>
                      </div>
                    }
                  </div>
                </div>
                {isOpen && (
                  <div className="flex flex-col ">
                    <label className={labelClass}>{labelValues.latitude}</label>
                    <div className={labelValueClass}>
                      {model.offerRequest.latitude}
                    </div>
                    <label className={labelClass}>
                      {labelValues.longitude}
                    </label>
                    <div className={labelValueClass}>
                      {model.offerRequest.longitude}
                    </div>
                    <div className={labelValueClass}>
                      {model.offerRequest.hasGas
                        ? labelValues.hasGas
                        : labelValues.hasNotGas}
                    </div>
                    {model.offerRequest.isExtended && (
                      <>
                        <label className={labelClass}>
                          {labelValues.usefulArea}
                        </label>
                        <div className={labelValueClass}>
                          {model.offerRequest.usefulArea}
                        </div>
                        <label className={labelClass}>
                          {labelValues.energeticClass}
                        </label>
                        <div className={labelValueClass}>
                          {
                            state.items.lookups.epcs.find(
                              (item) =>
                                item.id === model.offerRequest.energeticClass
                            )?.name
                          }
                        </div>
                        <label className={labelClass}>Lakosok száma</label>
                        <div className={labelValueClass}>
                          {model.offerRequest.adults} felnőtt,{" "}
                          {model.offerRequest.children} gyerek
                        </div>
                        <label className={labelClass}>
                          {labelValues.winterInsideTemparature}
                        </label>
                        <div className={labelValueClass}>
                          {model.offerRequest.winterInsideTemparature}
                        </div>
                        <label className={labelClass}>
                          {labelValues.summerInsideTemperature}
                        </label>
                        <div className={labelValueClass}>
                          {model.offerRequest.summerInsideTemperature}
                        </div>
                        <label className={labelClass}>
                          {labelValues.electricCarEnergyRequirement}
                        </label>
                        <div className={labelValueClass}>
                          {model.offerRequest.electricCarEnergyRequirement}
                        </div>
                        <label className={labelClass}>
                          {labelValues.otherEnergyRequirement}
                        </label>
                        <div className={labelValueClass}>
                          {model.offerRequest.otherEnergyRequirement}
                        </div>
                        {!isEnergeticClassSelected && (
                          <>
                            <label className={labelClass}>
                              {labelValues.wallType}
                            </label>
                            <div className={labelValueClass}>
                              {
                                state.items.lookups.walls.find(
                                  (item) =>
                                    item.id === model.offerRequest.wallType
                                )?.name
                              }
                            </div>
                            <label className={labelClass}>
                              {labelValues.windowType}
                            </label>
                            <div className={labelValueClass}>
                              {
                                state.items.lookups.windows.find(
                                  (item) =>
                                    item.id === model.offerRequest.windowType
                                )?.name
                              }
                            </div>
                            <label className={labelClass}>
                              {labelValues.windowAreaNorth}
                            </label>
                            <div className={labelValueClass}>
                              {model.offerRequest.windowAreaNorth}
                            </div>
                            <label className={labelClass}>
                              {labelValues.windowAreaSouth}
                            </label>
                            <div className={labelValueClass}>
                              {model.offerRequest.windowAreaSouth}
                            </div>
                            <label className={labelClass}>
                              {labelValues.doorType}
                            </label>
                            <div className={labelValueClass}>
                              {
                                state.items.lookups.doors.find(
                                  (item) =>
                                    item.id === model.offerRequest.doorType
                                )?.name
                              }
                            </div>
                            <label className={labelClass}>
                              {labelValues.doorAreaNorth}
                            </label>
                            <div className={labelValueClass}>
                              {model.offerRequest.doorAreaNorth}
                            </div>
                            <label className={labelClass}>
                              {labelValues.doorAreaSouth}
                            </label>
                            <div className={labelValueClass}>
                              {model.offerRequest.doorAreaSouth}
                            </div>
                            <label className={labelClass}>
                              {labelValues.roofType}
                            </label>
                            <div className={labelValueClass}>
                              {
                                state.items.lookups.roofs.find(
                                  (item) =>
                                    item.id === model.offerRequest.roofType
                                )?.name
                              }
                            </div>
                            <label className={labelClass}>
                              {labelValues.cellarType}
                            </label>
                            <div className={labelValueClass}>
                              {
                                state.items.lookups.cellars.find(
                                  (item) =>
                                    item.id === model.offerRequest.cellarType
                                )?.name
                              }
                            </div>
                            <label className={labelClass}>
                              {labelValues.heatExchangerType}
                            </label>
                            <div className={labelValueClass}>
                              {
                                state.items.lookups.heatExchangers.find(
                                  (item) =>
                                    item.id ===
                                    model.offerRequest.heatExchangerType
                                )?.name
                              }
                            </div>
                            <label className={labelClass}>
                              {labelValues.shadingType}
                            </label>
                            <div className={labelValueClass}>
                              {
                                state.items.lookups.shadings.find(
                                  (item) =>
                                    item.id === model.offerRequest.shadingType
                                )?.name
                              }
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="pt-6 w-[480px] z-10 text-lgx font-medium text-smart-darkgray text-justify">
            <div className="mb-4">
              <label className="text-lgx font-medium text-smart-darkgray">
                Ajánlott energiafelhasználási mix
              </label>
            </div>
            <div className="flex justify-center items-center">
              <div className="flex w-full h-full justify-center items-center m-4">
                {chartData && (
                  <Doughnut
                    id="doughnut-chart"
                    data={chartData}
                    options={ChartOptions}
                    plugins={[ShadowPlugin]}
                    width={320}
                    height={320}
                  />
                )}
              </div>
            </div>
            {resultData.energySourceMix.length > 0 ? (
              resultData.energySourceMix.map((item, index) => (
                <div key={index} className="w-[100%] mt-2">
                  <div className="flex align-middle border-b-[1px] border-smart-darkgray">
                    <div className="flex w-full pt-3">
                      <div
                        style={{
                          backgroundColor: `${BackgroundColors[index]}`,
                        }}
                        className="flex mt-[6px] mr-[6px] w-[16px] h-[16px] rounded-full border-[1px]"
                      ></div>
                      <div className="mr-4 mb-4">
                        <p className="text-lg font-medium text-smart-darkgray">
                          {item.energySource.name}
                        </p>
                        <p className="text-base">
                          Részarány a mixben: {item.ratio} %
                        </p>
                        {requestData.isExtended && item.hasCalculatedCost && (
                          <p className="text-base">
                            Csúcsteljesítmény igény: {item.pp} kW
                          </p>
                        )}
                        {requestData.isExtended && item.hasCalculatedCost && (
                          <p className="text-base">
                            Beszerzési és telepítési költség:{" "}
                            {currencyFormat(item.specificCost)} Ft
                          </p>
                        )}
                        {requestData.isExtended && !item.hasCalculatedCost && (
                          <p className="text-base">
                            Csatlakozási díj:{" "}
                            {currencyFormat(
                              item.energySource.informativeCost?.initialCost ??
                                0
                            )}{" "}
                            Ft
                          </p>
                        )}
                      </div>
                    </div>
                    {item.energySource.hasDescriptionPage && (
                      <div>
                        <img
                          className="w-6 h-6 m-4 cursor-pointer"
                          src={settingsSvg}
                          title="Részletek"
                          onClick={() => {
                            if (requestData || resultData) {
                              dispatch({
                                type: OfferDataActionType.setFormData,
                                params: {
                                  offerRequest: requestData,
                                  offerResult: resultData,
                                },
                              });
                              headerPathDispatch({
                                type: HeaderPathActionType.setPath,
                                params: {
                                  path: routePaths.OFFER,
                                },
                              });
                              navigate("/" + item.energySource.id, {});
                            }
                          }}
                        />
                      </div>
                    )}
                    {item.energySource.hasContractors && (
                      <div>
                        <img
                          className="w-6 h-6 m-4 cursor-pointer"
                          src={shippingSvg}
                          title="Kereskedő"
                          onClick={() => {
                            headerPathDispatch({
                              type: HeaderPathActionType.setPath,
                              params: {
                                path: routePaths.OFFER,
                              },
                            });
                            navigate(routePaths.CONTRACTOR, {
                              state: {
                                longitude: requestData.latitude,
                                latitude: requestData.longitude,
                                area: 10,
                                isGas: resultData.energySourceMix.some(
                                  (x) => x.energySource.id === "gas"
                                ),
                                isSolarPanel: resultData.energySourceMix.some(
                                  (x) => x.energySource.id === "solarPanel"
                                ),
                                isSolarCollector:
                                  resultData.energySourceMix.some(
                                    (x) =>
                                      x.energySource.id === "solarCollector"
                                  ),
                                isHeatPump: resultData.energySourceMix.some(
                                  (x) => x.energySource.id === "heatPump"
                                ),
                                isWindTurbine: resultData.energySourceMix.some(
                                  (x) => x.energySource.id === "windTurbine"
                                ),
                              },
                            });
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="flex text-left text-lg text-smart-red">
                Sajnos a megadott adatokhoz nincs megfelelő ajánlat (ellenőrizze
                a megadott földrajzi koordináták helyességét).
              </div>
            )}
            <div className="text-base my-4">
              <p>
                Átlag szélsebesség: {resultData.avgWindSpeedKmPH.toFixed(2)}{" "}
                km/h
              </p>
              <p>
                Nyári maximális hőmérsékletek átlaga:{" "}
                {resultData.summerTemperature.toFixed(2)} °C
              </p>
              <p>
                Téli minimális hőmérsékletek átlaga:{" "}
                {resultData.winterTemperature.toFixed(2)} °C
              </p>
              <p>
                Tengerszint feletti magasság: {resultData.altitude.toFixed(4)}
              </p>
            </div>
          </div>
          {requestData.isExtended && (
            <div>
              <div className="flex w-full border-t-2 items-center justify-center">
                <div className="w-[480px] m-4 text-justify">
                  <p className="text-lgx font-medium text-smart-darkgray mt-2 mb-2">
                    Megjegyzés
                  </p>
                  <li>
                    A gáz és az áram esetében a nem kedvezményes árakkal
                    kalkulál a rendszer.
                  </li>
                  <li>
                    Hálózati áram esetén a házon belüli kábelezés kiépítési alap
                    egységára 10.600 Ft/ nm. Egyedi igényektől függően ez az ár
                    jelentősen eltérhet, a pontos költségekhez részletes
                    árajánlat szükséges kivitelező szakembertől. A hálózatra
                    csatlakozás 2 fázisra és 16A-re vonatkozik, ettől eltérő
                    igények esetén érdeklődjön a helyi szolgáltatónál!{" "}
                  </li>
                  {requestData.hasGas && (
                    <li>
                      A gázvezeték épületen belüli kiépítésének alap egységára
                      13.200 Ft/nm, amely egy alap gázkazán árát is tartalmazza
                      már. Egyedi igényektől függően ez az ár jelentősen
                      eltérhet, a pontos költségekhez részletes árajánlat
                      szükséges kivitelező szakembertől. A hálózatra csatlakozás
                      ára szolgáltatási területenként változhat, pontos
                      összegekért érdeklődjön a helyi szolgáltatónál!{" "}
                    </li>
                  )}
                  <li>
                    A szélturbinák telepítése részben, a vízi energia és a
                    geotermál energia alkalmazása erős szabályozási /
                    engedélyezési problémákba ütközik.
                  </li>
                  <li>
                    A fenntartható zöld energiatermelés (mint a biogáz
                    előállítása, alga, alkalmazása, hidrogén termelés
                    napenergiával, hulladék gázosítás) jelenleg kisebb,
                    háztartási méretű rendszerekben nem hatékony.
                  </li>
                  <li>
                    Fejlett energiatermelés (kapcsolt áramtermelés, üzemanyag,
                    vagy hidrogén cellák alkalmazása), és az alternatív
                    energiaforrások (mint a hidrogén) használata ma még nem
                    költséghatékony a mikro energetikai rendszerekben.
                  </li>
                  <li>
                    Az energiatárolás a – a jelenlegi elszámolási rendszerben és
                    rövid időtávon – nem indokolt. Az elektromos akkumulátorok
                    alkalmazása inkább ellátásbiztonságot növelő eljárás.
                  </li>
                  <li>
                    A javasolt rendszer fajlagos élettartamciklus költsége{" "}
                    {totalCost} HUF /kWh.
                  </li>
                  <li>
                    A számított ár egy becslés, a szolgáltatóktól függően igen
                    jelentős (nagyobb, mint 30%) eltérés is lehet.
                  </li>
                </div>
              </div>
              <div className="flex w-full border-b-2 border-t-2 items-center justify-center">
                <div className="w-[480px] m-4 text-justify">
                  <p className="text-lgx font-medium text-smart-darkgray mt-2 mb-2">
                    Ajánlások
                  </p>
                  <div className="text-lg">
                    <p className="font-bold mt-4">Megjegyzés:</p>
                    Amennyiben a meleg víz jelenleg csak villamosenergia
                    használatával készül, vagy 2 felnőttnél több van, vagy 2
                    felnőtt és 2, esetleg több gyermek van, vagy saját
                    úszómedence van, akkor javasolt annak megfontolása, hogy a
                    melegvíz készítésére napkollektort szerel fel, mely az
                    energiaköltségeket további 2 – 4 %-kal csökkentheti.
                    <p className="font-bold text-smart-red mt-4">Figyelem:</p>
                    <p>
                      a szélturbinák laskossági alkalmazását jogszabályi
                      korlátozások befolyásolják. Ezért különösképpen érdemes
                      meggondolni a telepítését annak figyelembevételével is,
                      hogy szélsebesség mennyire stabil, hiszen a szélturbinák
                      jellemzően 3 m/s (10,8 km/h) szélsebesség felett indulnak
                      el.
                    </p>
                    <p className="font-bold mt-4">Megjegyzés:</p>
                    <p>
                      a jelenlegi jogszabályi környezetben saját mini víz
                      turbinák, alkalmazása nem oldható meg. Ugyanakkor célszerű
                      figyelni a jogszabályok változásait, hiszen ez egy nagyon
                      költségkímélő lehetőség, és amennyiben a fizikai
                      feltételek és a jogszabályi környezet megengedi, érdemes
                      ennek telepítését megfontolni.
                    </p>
                    <p className="font-bold mt-4">Megjegyzés:</p>
                    <p>
                      amennyiben az ön területén a geotermikus energia mértéke
                      meghaladja a magyar átlagértéket, akkor érdemes
                      megfontolni a geotermikus energia kihasználását. Sajnos a
                      jelenlegi árszínvonal és jogszabályi korlátozások miatt
                      ennek kihasználására nincsenek meg a feltételek,
                      mindenképp érdemes a piaci és jogszabályi környezetet
                      folyamatosan nyomon követni.
                    </p>
                    <p className="font-bold mt-4">Javaslat:</p>
                    <p>
                      amennyiben az ön telek mérete eléggé nagy (legalább 1600
                      m2), vagy mezőgazdasági tevékenység folyik, illetve ha
                      viszonylag sokan laknak (nagyobb, mint 5) az adott
                      helyszínen, illetve vagy gaz tüzelésű, esetleg
                      olajtüzelésű kazán áll rendelkezésre, vagy rendelkezik
                      olyan belsőégésű motorral, mini gázturbinával, mely áramot
                      termel, illetve alkalmazott kapcsolt energiatermeléssel,
                      akkor érdemes megfontolni a biogáz, üzemanyag termelést,
                      és ezzel 1 – 8 %-os energiaköltség csökkentés elérését.
                    </p>
                    <p className="font-bold mt-4">Megjegyzés:</p>
                    <p>
                      az alga alapú biomasszatermelés a leghatékonyabb mód, de
                      jelentősebb beruházást, felügyeletet és a felhasználás
                      menedzselést igényel.
                    </p>
                    <p className="font-bold text-smart-red mt-4">
                      Figyelmeztetés:
                    </p>
                    <p>
                      a biogáz, bioüzemanyag termeléshez csatlakoznia kell azok
                      felhasználást biztosító energiatermelő egységeknek is. A
                      feltétel teljesülésekor a javasolt szöveg:
                    </p>
                    <p className="font-bold mt-4">Javaslat:</p>
                    <p>
                      érdemes megfontolni elektromos akkumulátorok alkalmazását.
                      Ugyanakkor ezt két esetben célszerű alkalmazni:
                      <li>
                        sziget üzemmód, amikor a rendszer nem kapcsolódik a
                        villamos hálózathoz (vagy csak biztonsági tartalékként
                        kapcsolódik)
                      </li>
                      <li>
                        ha a megújuló energiatermelés részaránya eléri a 30%-ot
                      </li>
                    </p>
                    <p className="font-bold mt-4">Javaslat:</p>
                    <li>Amiket minden esetben érdemes megontolni:</li>
                    <li>A ház falát vonják be hőszigeteléssel</li>
                    <li>Modernizálja a nyílászáró szerkezeteket</li>
                    <li>
                      Szereljen a nyílászárókra árnyékoló spalettát, redőnyt,
                      vagy rolót!
                    </li>
                    <li>
                      Energetikai szempontból újítsák fel az épületet. (Javasolt
                      az energetikai tanúsítvány szerinti besorolást minimum egy
                      osztállyal javítani.)
                    </li>
                    <li>Alkalmazzon hőcserélős szellőztetési rendszert.</li>
                    <li>Alkalmazzon aktív árnyékoló rendszert.</li>
                    <p className="font-bold mt-4">Javaslat:</p>
                    <p>
                      az elektromos gépjárművek töltésére célszerű rövid távú
                      elektromos akkumulátoros energiatárolóval rendelkező
                      napelemes rendszert kiépíteni.
                    </p>
                    <p className="font-bold mt-4">Javaslat:</p>
                    <p>
                      a többnyire nem nyári időszakban jelentkező kiegészítő
                      energiaigény kielégítésére napelemes, szélturbinás
                      energiatermelést és hőszivattyú alkalmazása javasolt,
                      valamint, ha lehetséges a (pl. télikertek esetében)
                      megfelelő szerkezeti megoldás kiépítése.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {!model.offerRequest.isExtended && (
          <div className="flex justify-center items-center">
            <div className="pt-6 pb-2 w-[480px] z-10 text-xs font-medium text-smart-darkgray text-center">
              Ahhoz, hogy pontossan láthassa, milyen arányban szerepelnek a
              fenti technológiák az energiafelhasználási mixben és ezeknek mi a
              fajlagos költségük, kérjük, hogy töltse ki a további mezőket az
              Űrlapon.
            </div>
          </div>
        )}
      </div>
    </div>
  ) : null;
};

export default OfferResult;
