import { Component } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import { carouselData } from "./carousel-data";
import PageSection from "../../../common/components/page-section";
import PageSectionContent, { BodyWidth, VerticalMargins, Width } from "../../../common/components/page-section/page-section-content";

type State = {
    currentSlide: number;
    lastSlide: number;
    paused: boolean;
};

type Props = {
    timeOut: number;
};

class Carousel extends Component<Props, State>
{
    data = carouselData;
    animationTimer: NodeJS.Timer | null = null;

    constructor(props: Props)
    {
        super(props);
        this.state = { currentSlide: 0, lastSlide: -1, paused: false };
    }

    componentDidMount()
    {
        this.resetAnimationTimer();
    };

    nextSlideClicked()
    {
        this.setSlide(this.getNextSlide());
    };

    prevSlideClicked()
    {
        this.setSlide(this.getPrevSlide());
    };

    goToSlideClicked(index: number)
    {
        this.setSlide(index);
    };

    render()
    {
        return (
            <PageSection>
                <PageSectionContent width={Width.Full} bodyWidth={BodyWidth.Full} verticalMargins={VerticalMargins.None} additionalClassName="relative">
                    <FaChevronLeft className="absolute left-4 inset-y-1/2 text-4xl text-white cursor-pointer z-10" onClick={() => { this.prevSlideClicked(); }} />

                    {this.data.slides.map((slide, index) =>
                    {
                        return (
                            <img
                                src={slide.image}
                                key={index}
                                className={index === this.state.currentSlide ? "block w-full object-cover min-h-[400px] max-h-[600px]" : "hidden"}
                                onMouseEnter={() => { this.pauseAnimation(); }}
                                onMouseLeave={() => { this.continueAnimation(); }}
                            />
                        );
                    })}

                    <div className="absolute w-full flex justify-center bottom-14">
                        <div className="w-[350px] md:w-[600px] lg:w-[850px] bg-black/20 p-4 rounded backdrop-blur-sm">
                            <p className="text-4xl text-center text-white">
                                {this.data.slides[this.state.currentSlide].title}
                            </p>
                            {
                                this.data.slides[this.state.currentSlide].message?.map((messageLine, index) =>
                                {
                                    return (
                                        <p
                                            className="text-2xl text-center text-white mt-3 xs:hidden"
                                            key={index}>
                                            {messageLine}
                                        </p>
                                    );
                                })
                            }
                        </div>
                    </div>

                    <div className="absolute w-full flex justify-center bottom-0">
                        {this.data.slides.map((_, index) =>
                        {
                            return (
                                <div
                                    className={index === this.state.currentSlide ?
                                        "h-1 w-6 bg-gray-400 rounded-full mx-3 mb-6 cursor-pointer" :
                                        "h-1 w-6 bg-white rounded-full mx-3 mb-6 cursor-pointer"}
                                    key={index}
                                    onClick={() => { this.goToSlideClicked(index); }}
                                ></div>
                            );
                        })}
                    </div>

                    <FaChevronRight className="absolute right-4 inset-y-1/2 text-4xl text-white cursor-pointer z-10" onClick={() => { this.nextSlideClicked(); }} />
                </PageSectionContent>
            </PageSection>
        );
    };

    pauseAnimation()
    {
        this.setState({ paused: true });
    }

    continueAnimation()
    {
        this.setState({ paused: false });
    }

    getNextSlide(): number
    {
        return this.state.currentSlide === this.data.slides.length - 1 ? 0 : this.state.currentSlide + 1;
    }

    getPrevSlide(): number
    {
        return this.state.currentSlide === 0 ? this.data.slides.length - 1 : this.state.currentSlide - 1;
    }

    setSlide(index: number)
    {
        // Rákattintásnél, ha ugyanarra a slide-ra kattint, akkor megőrizzük az eredeti lastSlide-ot.
        let lastSlide = index !== this.state.currentSlide ? this.state.currentSlide : this.state.lastSlide;
        this.resetAnimationTimer();
        this.setState({ currentSlide: index, lastSlide: lastSlide });
    };

    resetAnimationTimer()
    {
        if (this.animationTimer)
        {
            clearInterval(this.animationTimer);
            this.animationTimer = null;
        }

        this.animationTimer = setInterval(() =>
        {
            if (this.state.paused === false)
            {
                this.setSlide(this.getNextSlide());
            }
        }, this.props.timeOut);
    }
}

export default Carousel;
