export const style = {
  inputClass:
    "border border-lightgray relative mb-6 p-4 h-14 w-full rounded-[16px] text-lg text-smart-darkgray focus:outline-smart-green",
  inputUnitClass:
    "border border-lightgray relative mb-6 p-4 h-14 w-full rounded-l-[16px] text-lg text-smart-darkgray focus:outline-smart-green",
  inputNearClass:
    "border border-lightgray mb-6 mr-4 p-4 h-14 rounded text-smart-darkgray focus:outline-smart-green",
  checkBoxClass: "m-4 w-6 h-6 rounded text-smart-darkgray",
  checkBoxListClass: "w-6 h-6 rounded text-smart-darkgray",
  labelValueClass: "mb-1 text-smart-darkgray",
  labelClass: "text-smart-midgray mb-4 ml-4 text-base",
  checkboxLabelClass: "text-smart-midgray mx-2 mb-4 text-base",
  divClass: "flex items-center",
  locationClass:
    "flex border-[1px] border-smart-darkgray text-smart-darkgray font-weight-[500] text-lg text-smart-black w-[156px] h-[48px] rounded-l-[24px] items-center justify-center",
  mapClass:
    "flex border-[1px] border-smart-darkgray text-smart-darkgray font-weight-[500] text-lg text-smart-black w-[130px] h-[48px] rounded-r-[24px] items-center justify-center",
};
