import { FaArrowLeft } from "react-icons/fa6";
import img from "../../../assets/solutions/geothermic.jpeg";
import { useNavigate } from "react-router-dom";
import routePaths from "../../../common/constants/route-paths";
import { useContext } from "react";
import { HeaderPathContext } from "../../../common/providers/header-path-context";

const Grid = () => {
  const navigate = useNavigate();
  const { headerPathState, dispatch: headerPathDispatch } =
    useContext(HeaderPathContext);

  return (
    <div className="flex justify-center">
      <div className="w-full h-[560px] absolute">
        <img className="w-full min-h-[380px] max-h-[600px]" src={img} />
      </div>
      <div className="flex relative">
        <div className="flex w-full">
          <div className="grow w-0"></div>
          <div className="shrink w-[1024px] min-w-[640px] z-10 pt-[160px] pb-[80px]">
            <div className="flex border-[1px] border-smart-lightgray bg-smart-gray-white rounded-[4px] justify-center items-center pb-[80px]">
              <div className="w-[480px]">
                <div className="mt-[30px]">
                  <div className="flex items-center">
                    <div
                      className="inline-flex items-center cursor-pointer"
                      onClick={() => {
                        navigate(
                          headerPathState.path ?? routePaths.HOME_SOLUTIONS
                        );
                      }}
                    >
                      <FaArrowLeft className="mr-2 inline" title="Vissza" />
                      Vissza a megoldások listájára
                    </div>
                  </div>
                </div>
                <div className="text-5xl text-smart-black font-bold mt-[50px]">
                  <p className="">Hálózat</p>
                </div>
                <div className="mt-4">
                  <p className="text-lg text-justify mt-8">
                    A hálózati energia, az energia felhasználókhoz továbbítására
                    szolgáló (villamosenergia, vagy gázszállító csőhálózat)
                    rendszer, mely tartalmazza az energiatermelő, tároló és a
                    rendszer működtetéséhez szükséges felügyeleti és menedzsment
                    eszközöket is.
                  </p>
                </div>
                <div>
                  <p className="text-lg text-justify mt-8">
                    A hálózati áram általában az elektromos energia olyan
                    formáját jelenti, amelyet az általános villamos hálózatokon
                    keresztül szállítanak és használnak. Ez az elektromos áram,
                    amely a fogyasztói otthonokba, vállalkozásokba, ipari
                    létesítményekbe és más helyszínekre jut el. A hálózati
                    áramot gyakran váltóáramként (AC) továbbítják, mivel az AC
                    könnyen átvihető nagy távolságokon és könnyen alakítható a
                    fogyasztói eszközök által használt különböző feszültségekre.
                  </p>
                </div>
                <div>
                  <p className="text-lg text-justify mt-8">
                    A hálózati áramot általában erőművek állítják elő, ahol a
                    mechanikai energia (például fosszilis tüzelőanyagok,
                    vízenergia, szélenergia vagy nukleáris energia) elektromos
                    energiává alakul át. Az áramot aztán vezetékeken és
                    hálózatokon keresztül továbbítják, mielőtt a fogyasztói
                    eszközökhöz érkezne.
                  </p>
                </div>
                <div>
                  <p className="text-lg text-justify mt-8">
                    Fontos megjegyezni, hogy a hálózati áram kifejezés gyakran
                    az AC áramot jelöli, de néha az elektromos energia általában
                    is hivatkozhat erre a fogalomra.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grow w-0"></div>
      </div>
    </div>
  );
};

export default Grid;
