import axios, { AxiosResponse, AxiosRequestConfig } from "axios";

//const baseURL = process.env.REACT_APP_API_BASE_URL;
const baseURL = window.location.origin;

const get = async <T>(url: string, config?: AxiosRequestConfig): Promise<T> => {
  const response: AxiosResponse<T> = await axios.get(
    `${baseURL}/${url}`,
    config
  );
  return response.data;
};

const post = async <T>(
  url: string,
  data: any,
  config?: AxiosRequestConfig
): Promise<T> => {
  const response: AxiosResponse<T> = await axios.post(
    `${baseURL}/${url}`,
    data,
    config
  );
  return response.data;
};

export { get, post };
