import { ReactNode, CSSProperties } from "react";

type Props = {
    children: ReactNode;
    additionalClassName?: string;
    style?: CSSProperties;
    id?: string;
};

const PageSection = ({ children, additionalClassName, style, id }: Props) =>
{
    const getClassName = () =>
    {
        return additionalClassName ? "flex w-full " + additionalClassName : "flex w-full";
    };

    return (
        <div className={getClassName()} style={style} id={id}>
            {children}
        </div>
    );
};

export default PageSection;
