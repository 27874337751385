import { CSSProperties, ReactNode } from "react";

export enum Width
{
    Default,
    Full
}

export enum BodyWidth
{
    Default,
    Narrow,
    Full,
}

export enum VerticalMargins
{
    Default,
    Top,
    Bottom,
    None,
}

type Props = {
    children: ReactNode;
    additionalClassName?: string;
    style?: CSSProperties;
    width?: Width;
    bodyWidth?: BodyWidth;
    verticalMargins?: VerticalMargins;
};

const PageSectionContent = ({ children, additionalClassName, style, width, bodyWidth, verticalMargins }: Props) =>
{
    const getClassName = () =>
    {
        let classes: string[] = [];

        if (width === Width.Default)
        {
            classes.push("w-[1024px] shrink min-w-[640px]");
        }
        else if (width === Width.Full)
        {
            classes.push("w-full");
        }
        else
        {
            classes.push("w-[1024px] shrink min-w-[640px]");
        }

        if (bodyWidth === BodyWidth.Default)
        {
            classes.push("px-5");
        }
        else if (bodyWidth === BodyWidth.Narrow)
        {
            classes.push("px-24");
        }
        else if (bodyWidth === BodyWidth.Full)
        {
            classes.push("px-0");
        }
        else
        {
            classes.push("px-5");
        }

        if (verticalMargins === VerticalMargins.Default)
        {
            classes.push("py-12");
        }
        else if (verticalMargins === VerticalMargins.Top)
        {
            classes.push("pt-12");
        }
        else if (verticalMargins === VerticalMargins.Bottom)
        {
            classes.push("pb-12");
        }
        else if (verticalMargins === VerticalMargins.None)
        {
            classes.push("py-0");
        }
        else
        {
            classes.push("py-12");
        }

        let result = classes.join(" ");

        if (!!additionalClassName)
        {
            result += " " + additionalClassName;
        }

        return result;
    };

    return (
        <div className={getClassName()} style={style}>
            {children}
        </div>
    );
};

export default PageSectionContent;
