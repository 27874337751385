import SVG from "react-inlinesvg";
import openIcon from "../../../../assets/home/energetic-defs/open-icon.svg";
import { MouseEventHandler } from "react";

type Props = {
  title: string;
  onClick: MouseEventHandler<HTMLDivElement>;
  children: never[];
};

const EnergeticDefinitionItem = ({ title, onClick }: Props) => {
  return (
    <div
      className="flex place-content-stretch cursor-pointer hover:bg-white/30"
      onClick={(e) => onClick(e)}
    >
      <div className="flex grow p-4 inline-block">{title}</div>
      <div className="flex-none p-4 w-14 place-items-center">
        <div>
          <img className="flex h-[32px] w-[32]" src={openIcon} title="Leírás" />
        </div>
      </div>
    </div>
  );
};

export default EnergeticDefinitionItem;
