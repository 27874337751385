import { detailStyles } from "./detail-styles";

export const systemPartsContent = {
    title: "Energiarendszerek elemei",
    body: (
        <>
            <p>
                <span className={detailStyles.highlightStrong}>Hálózat</span> az energia rendszer elemeit összekötő rendszer, mely egyben önálló
                elszámolású energia rendszereket is egy közös rendszerbe köthet (pl. a városi, vagy országos villamosenergia rendszer esetében).
            </p>
            <p>
                <span className={detailStyles.highlightStrong}>Energiatermelő egység</span> olyan berendezés, eszköz, mely az energiaforrásokat
                felhasználva szabadon felhasználható energiát állít elő, pl. napenergiából villamosáramot, vagy energiaforrás eltüzelésével
                mechanikai energiát állít elő, mint a belsőégésű motor.
            </p>
            <p>
                <span className={detailStyles.highlightStrong}>Inverter</span> a napelemes energia rendszerek fontos eleme, mely a napelemek által
                termelt alacsony feszültségű egyenáramot az elektromos eszközök működtetésére és  a villamos hálózatba csatlakozáshoz szükséges
                magasabb feszültségű váltakozó árammá alakítja át.
            </p>
            <p>
                <span className={detailStyles.highlightStrong}>Energiatároló</span> az energia rövidebb, hosszabb idejű tárolására szolgáló
                rendszerelem, mely lehet villamos akkumulátor, hidrogént termelő, majd azt energiává visszaalakító elem, vagy mechanikai energiatárolás
                nagy tömegű forgó rendszer, vagy vizet magasabb felszintre pumpáló rendszer, mely az energiát később víz turbinákkal nyeri vissza, stb.
            </p>
            <p>
                <span className={detailStyles.highlightStrong}>Energia visszanyerés</span>, közismerten ilyen a hőcserélő, mely pl. a távozó meleg
                levegő energiáját nyeri vissza, vagy nyáron a szellőzéskor távozó levegővel hűtik le a beáramló levegőt.
            </p>
            <p>
                <span className={detailStyles.highlightStrong}>Szellőztető</span> passzívan, vagy aktívan közegcserét (levegő forgását) végrehajtó
                eszköz. Ide tartoznak a ventilátorok is.
            </p>
            <p>
                <span className={detailStyles.highlightStrong}>Árnyékoló</span> passzívan (fixen beépítve), vagy aktívan a környezeti jellemzőktől
                függően működtetett árnyékoló eszköz / eszközrendszer.
            </p>
            <p>
                <span className={detailStyles.highlightStrong}>Érzékelő elemek</span> közegjellemezőket (hőmérséklet, szélsebesség) felhasználási
                jellemzőket (energiafogyasztást), energiafelhasználást befolyásoló tényezőket, eseményeket (pl. egy szoba foglaltságát) érzékelő
                eszközök.
            </p>
            <p>
                <span className={detailStyles.highlightStrong}>Beavatkozó elemek</span> az irányítás során a rendszerből eredő saját belső, vagy kívülről
                (a működtető operátor/személy által) befolyásoló, szabályozó hatásokat végrehajtó elemek, eszközök.
            </p>
            <p>
                <span className={detailStyles.highlightStrong}>Felügyeleti alrendszer</span> az energetikai (mikro /háztartási) rendszer jellemzőit
                folyamatosan mérő, gyűjtő és értékelő elemek összessége.
            </p>
            <p>
                <span className={detailStyles.highlightStrong}>Adatgyűjtő és diagnosztikai alrendszer</span> a felügyeleti rendszerhez hasonló alrendszer,
                mely a mért és gyűjtött adatokat diagnosztikai céllal időszakosan, vagy folyamatos kiértékeli.
            </p>
            <p>
                <span className={detailStyles.highlightStrong}>Szabályzó alrendszer</span> általánosan használt megoldás, mely során a rendszer
                jellemzőinek a mérése alapján az előirt 6elvárt értékektőli eltérés automatikusan módosul a rendszer működése (ilyet használnak
                pl. fűtéskor a hőmérséklet mérésével).
            </p>
            <p>
                <span className={detailStyles.highlightStrong}>Irányítás</span> a rendszer működésének a befolyásolása mért rendszerjellemzők és
                a működési céltól (elvárt jellemzők értékétől9, az azoktóli eltéréstől) függően. Két alapvető megoldása létezik, a vezérlés (közvetlen
                beavatkozás a rendszer működésébe, - nyílt irányítás) és a szabályozás (zárt irányítás).
            </p>
            <p>
                <span className={detailStyles.highlightStrong}>Menedzselés</span> az erőforrásokat az elvárástól/céltól és a valós körülményektől függő
                befolyásolása a (költség, vagy tisztább) hatékony működés érdekében.
            </p>
        </>
    )
};